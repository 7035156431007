.pagination
    display: flex
    padding: 0
    list-style: none
    margin: 5px 0
    &.is-justify-centered
        justify-content: center
    &.is-justify-end
        justify-content: flex-end
    &--item
        .is-full-length &
            flex-grow: 1
        a
            line-height: 1.25
            text-align: center
            color: color-theme(primary)
            background-color: color-theme(light-grey)
            border: 1px solid lighten(color-theme(light-grey), 5)
            position: relative
            display: block
            padding: 8px 12px
            margin-left: -1px
            &:not(.is-disabled)
                &:hover,
                &.is-active
                    background-color: color-theme(primary)
                    color: color-theme(light-grey)
            &.is-disabled
                cursor: initial
                color: lighten(color-theme(light-grey), 10)
        &:first-child
            a
                border-top-left-radius: 10px
                border-bottom-left-radius: 10px
        &:last-child
            a
                border-top-right-radius: 10px
                border-bottom-right-radius: 10px