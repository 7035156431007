.login
    width: 100vw
    height: 100vh
    background-color: black
    display: flex
    flex-direction: column
    position: relative
    &--main
        flex-grow: 1
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        position: relative
        @include breakpoint('mobile')
            padding: 20px
        &-background
            filter: invert(1)
            overflow: hidden
            position: absolute
            bottom: 0
            right: 0
            left: 0
            top: 0
            z-index: 1
            video
                height: 100%
        &-card
            width: 100%
            max-width: 450px
            border-radius: 6px
            background-color: var(--neutral-200)
            -webkit-backdrop-filter: blur(3px)
            backdrop-filter: blur(3px)
            margin: 0 20px
            padding: 30px
            position: relative
            z-index: 2
            &.is-shaking
                animation: shake 1s cubic-bezier(.36,.07,.19,.97) both
                transform: translate3d(0, 0, 0)
            &-brand
                text-align: center
                padding: 20px 0 30px
                svg
                    width: 140px
            h1
                font-size: 22px
                color: var(--symbioze)
                padding-bottom: 10px
                @include breakpoint('mobile')
                    font-size: 18px
            h2
                font-size: 14px
                text-transform: none
                letter-spacing: 0
                color: color-theme('grey400')
                padding-bottom: 10px
                @include breakpoint('mobile')
                    font-size: 13px
        form
            padding: 30px 0
            .form
                &--group
                    flex-direction: column
                    border-bottom: none
                    label
                        flex-basis: 0
            .button
                width: 100%
                margin-top: 30px
        &-password-lost
            margin-top: 12px
            span
                font-style: italic
                font-weight: 300
                color: color-theme(primary)
                cursor: pointer
                display: block
            &:hover
                span
                    text-decoration: underline
    &--bottom
        flex-shrink: 0
        flex-grow: 0
        display: flex
        flex-direction: row
        justify-items: flex-start
        padding: 10px 20px