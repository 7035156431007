.form
    &--group
        padding: 8px 0
        border-bottom: 1px solid transparentize(color-theme('grey200'), .5)
        flex-direction: row
        display: flex
        @include breakpoint(mobile)
            flex-direction: column
            border-bottom: none
            padding: 0
            margin-bottom: 20px
        &:last-child
            border-bottom: none
        label
            cursor: default
            color: color-theme('grey400')
            flex-basis: 200px
            padding: 12px 0px
            flex-shrink: 0
            @include breakpoint(mobile)
                flex-basis: inherit
                margin-bottom: 10px
                padding: 0
            svg
                width: 20px
                height: 20px
                stroke: color-theme('grey400')
        &.has-svg
            label
                padding: 8px 0 7px
        &-input
            flex-grow: 1
            blockquote
                background-color: transparent
                border-left: none
                color: color-theme('grey400')
                svg
                    stroke: color-theme('grey400')
            &.is-color-picker
                position: relative
                input
                    max-width: 200px
                    padding-left: 50px
                &:before
                    content: '#'
                    font-size: 18px
                    font-weight: 600
                    border-right: 1px solid color-theme('grey300')
                    color: color-theme('grey400')
                    position: absolute
                    padding: 7px 15px
                    display: block
                    z-index: 2
                    left: 0
                    top: 1px
        &-select
            flex-grow: 1
            position: relative
            &:before
                content: ''
                width: 6px
                height: 6px
                pointer-events: none
                border-left: 1.5px solid color-theme('grey400')
                border-bottom: 1.5px solid color-theme('grey400')
                position: absolute
                right: 15px
                top: 23px
                transform: rotate(-45deg)
                margin-top: -9px
            blockquote
                background-color: transparent
                border-left: none
                color: color-theme('grey400')
                svg
                    stroke: color-theme('grey400')
                ul
                    list-style: square
                    padding-left: 18px
                    li
                        padding-top: 4px
        &-text
            font-style: italic
            padding: 9px 0px
            flex-grow: 1
        &-range
            background-color: color-theme('grey200')
            border: 1px solid color-theme('grey300')
            box-shadow: 0 1px 2px transparentize(color-theme(grey0), .7)
            border-radius: 4px
            padding: 2px 8px
            display: flex
            flex-direction: row
            flex-grow: 1
            flex-shrink: 0
            &-nbr
                min-width: 36px
                font-size: 10px
                color: color-theme('grey500')
                text-transform: uppercase
                padding-left: 4px
                flex-grow: 1
                flex-shrink: 0
                display: flex
                flex-direction: row
                align-self: center
                justify-content: flex-end
                &-pixels
                    color: color-theme('grey400')
                    padding-left: 4px
        &-nine-position
            display: flex
            flex-direction: row
            flex-wrap: wrap
            margin-left: -4px
            margin-right: -4px
            margin-bottom: 4px
            &-btn
                width: calc(33.3333% - 8px)
                height: 28px
                transition: all .3s ease
                background-color: var(--neutral-300)
                border: 1px solid var(--neutral-400)
                box-shadow: 0 1px 2px transparentize(color-theme(grey0), .7)
                cursor: pointer
                border-radius: 4px
                margin: 4px
                display: flex
                justify-content: center
                align-items: center
                svg
                    width: 24px
                    height: 24px
                    stroke: var(--neutral-500)
                    display: block
                &:hover
                    border-color: var(--symbioze)
                &.is-active
                    border-color: var(--symbioze)
                    background-color: var(--symbioze)
                    svg
                        stroke: var(--neutral-100)
        &.is-vertical
            flex-direction: column
            border-bottom: none
            padding: 0
            margin-bottom: 20px
            &:last-child
                margin-bottom: 0
            label
                flex-basis: inherit
                margin-bottom: 4px
                padding: 0 0 10px 0
    &--validations-error
        font-size: 12px
        font-weight: 500
        color: color-theme(error)
        margin-top: 6px
        margin-bottom: 5px
        display: flex
        flex-direction: row
        justify-content: flex-start
        align-items: center
        svg
            width: 24px
            height: 24px
            stroke: color-theme(error)
            margin-right: 10px

    &--segmented-controls
        padding-bottom: 20px