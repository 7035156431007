.badge
    font-size: 10px
    font-weight: 600
    color: transparentize(color-theme('grey500'), .2)
    text-align: center
    text-transform: uppercase
    background-color: #666
    border-radius: 3px
    display: inline-block
    padding: 3px 12px 3px
    svg
        width: 12px
        height: auto
        display: inline-block
        vertical-align: -2px
        *
            fill: white
    &.is-facebook
        background-color: color-corporate(facebook)
    &.is-twitter
        background-color: color-corporate(twitter)
    &.is-google
        background-color: color-corporate(google)
    &.is-success
        background-color: color-theme(success)
        color: var(--neutral-200)
    &.is-error
        background-color: color-theme(error)
    &.is-trial
        background-color: #FF9900
        color: var(--neutral-200)