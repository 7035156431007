.games
    &--popular
        display: flex
        flex-direction: row
        .swiper-container
            width: 100%
            flex-shrink: 0
            flex-grow: 1
        .dashboard-card
            &:first-child
                margin-left: 0
            &:last-child
                margin-right: 0