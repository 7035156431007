blockquote
    font-size: 12px
    font-style: italic
    color: color-theme('grey500')
    background-color: transparentize(color-theme('grey300'), .5)
    padding: 5px 8px 4px
    border-radius: 4px
    border-left: 2px solid var(--symbioze)
    margin: 4px 0 0 0
    svg
        width: 12px
        height: 12px
        stroke: var(--symbioze)
        margin-top: -2px
        vertical-align: -2px
        margin-right: 8px
        display: inline-block