.list-page
    padding-bottom: 30px
    &--header
        display: flex
        flex-direction: row
        justify-content: space-between
        margin-bottom: 20px
        &-actions
            button
                margin-right: 10px
                &:last-child
                    margin-right: 0