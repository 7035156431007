.users
    &--settings
        &-info
            &-avatar
                width: 64px
                height: 64px
                font-size: 26px
                font-weight: 600
                color: var(--neutral-200)
                border-radius: 64px
                background-color: var(--symbioze)
                transition: all .2s ease
                display: flex
                align-items: center
                justify-content: center
                @include breakpoint(mobile)
                    width: 26px
                    height: 26px
                    font-size: 12px
                    margin-left: 0