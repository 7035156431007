.scaffolding
    &--navigation
        display: flex
        flex-direction: column
        &-category
            min-height: 42px
            font-size: 14px
            color: color-theme('grey400')
            background-color: transparent
            border-radius: 6px
            transition: all .2s ease
            cursor: pointer
            padding: 0 16px
            display: flex
            flex-direction: row
            justify-content: space-between
            align-items: center
            margin-left: -16px
            &-icon
                flex-grow: 0
                flex-shrink: 1
                padding-right: 10px
                svg
                    width: 24px
                    height: 24px
                    transition: all .2s ease
                    stroke: color-theme('grey400')
            &-title
                flex-grow: 1
                flex-shrink: 0
                padding-bottom: 2px
                user-select: none
            &-arrow
                flex-grow: 0
                flex-shrink: 1
                svg
                    width: 12px
                    height: 12px
                    transition: all .2s ease
                    transform: rotate(180deg)
                    stroke: color-theme('grey400')
                    .is-opened &
                        transform: rotate(0deg)
            &:hover
                color: color-theme('grey500')
                background-color: var(--neutral-200)
                svg
                    stroke: color-theme('grey500')
        &-items-list
            margin-bottom: 10px
            --scaffolding-navigation-height: 0
            height: var(--scaffolding-navigation-height)
            transition: all .2s ease
            overflow: hidden
            margin-left: -16px
            &-item
                min-height: 42px
                padding: 0 16px
                font-size: 14px
                color: color-theme('grey400')
                cursor: pointer
                background-color: transparent
                border-radius: 6px
                user-select: none
                transition: all .2s ease
                display: flex
                flex-direction: row
                align-items: center
                &:hover
                    color: color-theme('grey500')
                    background-color: var(--neutral-200)
                &.is-active
                    color: var(--symbioze)