.games
    &--icon
        width: 30px
        height: 30px
        border-radius: 3px
        background-color: #666
        overflow: hidden
        display: block
        img
            width: 30px
            height: 30px
            display: block
