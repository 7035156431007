.card
    width: 100%
    text-align: left
    color: white
    background-color: var(--neutral-200)
    border: 1px solid color-theme('grey200')
    border-radius: 4px
    display: flex
    justify-content: space-between
    margin-bottom: 10px
    padding: 16px 22px
    transition: all .2s ease
    position: relative
    overflow: hidden
    &.has-link
        cursor: pointer
    &--flare
        width: 256px
        height: 256px
        border-radius: 256px
        background: radial-gradient(circle, rgba(var(--symbioze-rgb), .15) 0%, rgba(var(--symbioze-rgb), 0) 72%)
        transition: opacity .3s ease
        opacity: 0
        pointer-events: none
        position: absolute
        z-index: 1
        left: 0
        top: 0
    &--content
        display: flex
        flex-direction: column
        position: relative
        z-index: 2
        flex-grow: 1
        &-title
            font-size: 14px
            font-weight: 500
            color: color-theme('grey500')
            line-height: 1.5
            text-decoration: none
            transition: all .3s ease
        &-subtitle
            font-family: 'Montserrat'
            font-size: 10px
            font-weight: 400
            letter-spacing: 2px
            color: color-theme('grey400')
            text-transform: uppercase
            line-height: 1.625
            text-decoration: none
            transition: all .3s ease
    &--icon
        width: 32px
        height: 32px
        align-self: center
        transition: all .2s ease
        position: relative
        z-index: 2
        img
            width: 32px
            height: auto
            display: block
        svg
            width: 32px
            height: auto
            stroke: color-theme('grey400')
            transition: all .3s ease
    &:hover
        text-decoration: none
        border: 1px solid rgba(var(--symbioze-rgb), .15)
    &:hover
        .card--content-title
            color: var(--symbioze)
        .card--content-subtitle
            color: rgba(var(--symbioze-rgb), .5)
    &:hover
        .card--icon
            color: var(--symbioze)
            svg
                stroke: var(--symbioze)
        .card--flare
            opacity: 1
    &.is-smaller
        padding: 10px 22px
        .card--icon
            width: 32px
            height: 16px
            svg
                width: 32px
                height: 16px
    &.is-primary
        background-color: color-theme('primary')
        border-color: color-theme('primary')
        &:hover
            background-color: lighten(color-theme('primary'), .1)
            border-color: darken(color-theme('primary'), .1)
        .card--icon
            svg
                stroke: var(--neutral-200)
        .card--content-subtitle
            color: var(--neutral-200)
        .card--content-title
            color: var(--neutral-200)
        .card--flare
            background: radial-gradient(circle, transparentize(white, .85) 0%, transparentize(white, 1) 72%)