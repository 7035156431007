.tag
    font-size: 12px
    font-weight: 600
    color: white
    border-radius: 25px
    background-color: lighten(color-theme(light-grey), 5%)
    padding: 6px 12px
    display: inline-block
    &.is-premium
        background-image: linear-gradient(to right, #ffde01, #fe5c2b)
        color: color-theme(light-grey)
    svg
        width: 14px
        height: auto
        margin-right: 4px
        vertical-align: -2px
