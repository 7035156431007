@keyframes background_defilant {
	from {
		background-position: 0px 0px;
	}
	to {
		background-position: 300px 250px;
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@keyframes loading-background {
	from {
		background-position: 0px 0px;
	}
	to {
		background-position: -500px 0;
	}
}

@keyframes showFromUp {
	from {
		transform: translate3D(0, -50px, 0);
		opacity: 0;
	} to {
        transform: translate3D(0, 0, 0);
        opacity: 1;
	}
}

@keyframes showFromBottom {
	from {
		transform: translate3D(0, 100vh, 0);
		opacity: 1;
	} to {
        transform: translate3D(0, 0, 0);
        opacity: 1;
	}
}

@keyframes showFromRight {
	from {
		transform: translate3D(100px, 0, 0);
		opacity: 0;
	} to {
        transform: translate3D(0, 0, 0);
        opacity: 1;
	}
}

@keyframes showOverlay {
	from {
		opacity: 0;
	} to {
		opacity: 1;
	}
}

@keyframes blink {
	0% {
		opacity: .25;
	} 50% {
        opacity: 1;
	} 100% {
		opacity: .25;
	}
}

@keyframes shake {
	10%, 90% {
	  transform: translate3d(-1px, 0, 0);
	}
	
	20%, 80% {
	  transform: translate3d(2px, 0, 0);
	}
  
	30%, 50%, 70% {
	  transform: translate3d(-6px, 0, 0);
	}
  
	40%, 60% {
	  transform: translate3d(6px, 0, 0);
	}
  }