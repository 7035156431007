.viewport
    &--header
        height: 70px
        background-color: var(--header-color)
        box-shadow: 0 2px 12px transparentize(black, .8)
        padding: 12px 20px
        position: static
        z-index: 10
        margin: 0 auto
        display: flex
        &.is-menu-opened
            flex-wrap: wrap
        .grid-wrapper
            display: flex
            flex-direction: row
            justify-content: space-between
            padding: 0
        &-hamburger
            width: 42px
            height: 42px
            order: 1
            display: none
            @include breakpointWeb('mobile')
                display: block
                z-index: 2
            svg
                width: 42px
                height: 42px
                path
                    transition: all .5s ease
            &-line
                fill: none
                stroke: var(--header-text-color)
                stroke-width: 6
                transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1)
                &.is-line-1
                    stroke-dasharray: 60 207
                    stroke-width: 6
                    .is-menu-opened &
                        stroke-dasharray: 90 207
                        stroke-dashoffset: -134
                        stroke-width: 6
                &.is-line-2
                    stroke-dasharray: 60 60
                    stroke-width: 6
                    .is-menu-opened &
                        stroke-dasharray: 1 60
                        stroke-dashoffset: -30
                        stroke-width: 6
                &.is-line-3
                    stroke-dasharray: 60 207
                    stroke-width: 6
                    .is-menu-opened &
                        stroke-dasharray: 90 207
                        stroke-dashoffset: -134
                        stroke-width: 6
        &-logo
            min-width: 120px
            min-height: 20px
            display: flex
            order: 2
            align-items: center
            flex-shrink: 0
            flex-grow: 0
            @include breakpointWeb('mobile')
                flex-grow: 1
                justify-content: center
            svg
                height: 100%
                max-height: 28px
                @include breakpointWeb('mobile')
                    margin-left: -42px
            img
                height: 100%
                max-height: 28px
                @include breakpointWeb('mobile')
                    margin-left: -42px
        &-navigation
            flex-grow: 1
            order: 3
            display: flex
            justify-content: flex-end
            align-items: center
            @include breakpointWeb('mobile')
                display: none
            ul
                display: flex
                flex-direction: row
                justify-items: flex-start
                align-items: center
                padding-right: 20px
                li
                    font-size: 16px
                    color: var(--header-text-color)
                    padding: 10px 20px
                    position: relative
                    a
                        color: var(--header-text-color)
                        &:hover
                            color: var(--primary-color)
                    &:hover
                        color: var(--primary-color)
                        .viewport--header-dropdown
                            transform: scale(1)
                            opacity: 1
                            pointer-events: all
                    &.has-dropdown
                        padding-right: 24px
                        &:after
                            content: ''
                            width: 6px
                            height: 6px
                            transform: rotate(-45deg)
                            border-left: 2px solid var(--header-text-color)
                            border-bottom: 2px solid var(--header-text-color)
                            display: block
                            position: absolute
                            right: 5px
                            top: 50%
                            margin-top: -6px
                            @include breakpointWeb('mobile')
                                display: none
                        &:hover
                            &:after
                                border-color: var(--primary-color)
            .is-menu-opened &
                @include breakpointWeb('mobile')
                    display: block
                    height: calc(100vh - 70px)
                    background-color: var(--header-color)
                    margin-top: 70px
                    position: absolute
                    right: 0
                    left: 0
                    top: 0
                    padding: 20px
                    ul
                        flex-grow: 1
                        flex-direction: column
                        li
                            width: 100%
                            &.has-dropdown
                                padding-right: 40px

        &-dropdown
            width: 200px
            transition: all .2s ease
            transform: scale(.9)
            opacity: 0
            pointer-events: none
            position: absolute
            top: 25px
            z-index: 2
            left: 50%
            margin-left: -100px
            padding-top: 40px
            @include breakpointWeb('mobile')
                opacity: 1
                transform: scale(1)
                padding-top: 10px
                margin-left: 0
                position: static
                left: 0
                top: 0
            ul
                background-color: var(--header-color)
                box-shadow: 0 2px 8px transparentize(black, .8)
                display: flex
                flex-direction: column
                align-items: flex-start
                border-radius: 14px
                padding: 8px 0
                @include breakpointWeb('mobile')
                    padding: 0
                    box-shadow: none
                li
                    width: 100%
                    flex-grow: 1
                    flex-shrink: 0
                    padding: 4px 12px 0 12px
                    @include breakpointWeb('mobile')
                        padding: 8px 12px
                    &:last-child
                        padding-bottom: 4px
                    a
                        display: block
                        position: relative
                        padding: 10px
                        &:before
                            content: ''
                            background-color: var(--primary-color)
                            transition: all .2s ease
                            transform: scale(.8)
                            border-radius: 6px
                            opacity: 0
                            position: absolute
                            z-index: 1
                            bottom: 0
                            right: 0
                            left: 0
                            top: 0
                            @include breakpointWeb('mobile')
                                display: none
                        &:hover
                            &:before
                                transform: scale(1)
                                opacity: .3
        &-actions
            display: flex
            align-items: center
            flex-shrink: 0
            flex-grow: 0
            order: 4
            @include breakpointWeb('mobile')
                display: none
            button,
            a.button
                background-color: var(--primary-color)
                color: var(--header-color)

// --- VARIANTS
.viewport
    &--header
        &.is-static
            position: relative
        &.is-sticky
            position: sticky
            top: 0
        &.is-floating
            max-width: $website-max-width
            border-radius: 8px
            position: sticky
            top: 10px
            margin: 10px auto
            @media (max-width: 1330px)
                margin: 10px
            @include breakpointWeb('mobile')
                margin: 10px
            .page-builder--web &
                margin: 10px
        &.is-blurred
            background-color: rgba(var(--header-color-rgb), .85)
            -webkit-backdrop-filter: blur(10px)
            backdrop-filter: blur(10px)
        
        &-dropdown
            ul
                .is-blurred &
                    background-color: rgba(var(--header-color-rgb), .85)
                    -webkit-backdrop-filter: blur(10px)
                    backdrop-filter: blur(10px)
                    @include breakpointWeb('mobile')
                        background-color: transparent
                        backdrop-filter: none

        &-navigation
            .is-blurred &
                @include breakpointWeb('mobile')
                    background-color: rgba(var(--header-color-rgb), .85)
                    -webkit-backdrop-filter: blur(10px)
                    backdrop-filter: blur(10px)
            .is-floating &
                @include breakpointWeb('mobile')
                    height: calc(100vh - 100px)
                    margin-top: 80px
                    border-radius: 8px
            .is-sticky &
                @include breakpointWeb('mobile')
                    height: calc(100vh - 70px)
    &--content
        .has-sticky-header &
            margin-top: -70px
        .has-floating-header &
            margin-top: -90px