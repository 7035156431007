.lightbox
    width: 100%
    height: 100vh
    background-color: transparentize(black, .3)
    backdrop-filter: blur(20px)
    transition: all .3s ease
    position: fixed
    z-index: 50
    top: 0
    padding: 50px 0
    display: flex
    flex-direction: row
    justify-content: space-between
    @include animation(fadeIn .3s 1 ease)
    &.fadeOut
        @include animation(fadeOut .3s 1 ease)
    &--close
        width: 42px
        height: 42px
        text-indent: -9999px
        background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="white"><line x1="9.16992" y1="14.8299" x2="14.8299" y2="9.16992" id="Path"></line><line x1="14.8299" y1="14.8299" x2="9.16992" y2="9.16992" id="Path"></line></svg>')
        background-color: transparent
        background-repeat: no-repeat
        background-size: contain
        background-position: center center
        appearance: none
        opacity: .5
        transition: all .3s ease
        position: absolute
        z-index: 1
        right: 10px
        top: 10px
        &:hover
            opacity: 1
    &--prev
        width: 42px
        text-indent: -9999px
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"><path d="M12.0000234,14.8766353 C11.7779321,14.8766353 11.5558408,14.7948122 11.3805055,14.6194769 L7.2542361,10.4932543 C6.91525463,10.1542728 6.91525463,9.59320003 7.2542361,9.25421857 C7.59321757,8.91526048 8.15429034,8.91526048 8.4932718,9.25421857 L12.0000234,12.7609234 L15.5067282,9.25421857 C15.8457097,8.91526048 16.4067824,8.91526048 16.7457639,9.25421857 C17.0847454,9.59320003 17.0847454,10.1542728 16.7457639,10.4932543 L12.6195412,14.6194769 C12.444206,14.7948122 12.2221147,14.8766353 12.0000234,14.8766353 Z" transform="translate(12.000000, 11.938318) rotate(-270.000000) translate(-12.000000, -11.938318)"></path></svg>')
        background-color: transparent
        background-repeat: no-repeat
        background-size: contain
        background-position: center center
        opacity: .5
        transition: all .3s ease
        display: flex
        flex-shrink: 0
        flex-direction: column
        align-items: center
        appearance: none
        @include breakpoint('mobile')
            width: 32px
        &:hover
            opacity: 1
    &--next
        width: 42px
        text-indent: -9999px
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"><path d="M12.0000234,14.8766353 C11.7779321,14.8766353 11.5558408,14.7948122 11.3805055,14.6194769 L7.2542361,10.4932543 C6.91525463,10.1542728 6.91525463,9.59320003 7.2542361,9.25421857 C7.59321757,8.91526048 8.15429034,8.91526048 8.4932718,9.25421857 L12.0000234,12.7609234 L15.5067282,9.25421857 C15.8457097,8.91526048 16.4067824,8.91526048 16.7457639,9.25421857 C17.0847454,9.59320003 17.0847454,10.1542728 16.7457639,10.4932543 L12.6195412,14.6194769 C12.444206,14.7948122 12.2221147,14.8766353 12.0000234,14.8766353 Z" transform="translate(12.000000, 11.938318) rotate(-90.000000) translate(-12.000000, -11.938318)"></path></svg>')
        background-color: transparent
        background-repeat: no-repeat
        background-size: contain
        background-position: center center
        opacity: .5
        transition: all .3s ease
        display: flex
        flex-shrink: 0
        flex-direction: column
        align-items: center
        appearance: none
        @include breakpoint('mobile')
            width: 32px
        &:hover
            opacity: 1
    &--loader
        display: flex
        flex-grow: 1
        flex-shrink: 0
        flex-direction: column
        justify-items: center
        align-items: center
    &--container
        display: flex
        flex-direction: row
        justify-items: center
        align-items: center
        position: relative
        z-index: 2
        transition: all .2s ease
        &-content
            max-height: 100vh
            padding: 50px 0
            display: flex
            @include animation(displayContent .5s 1 ease)
            &.fromLeft
                @include animation(fromLeft .3s 1 ease)
            &.fromRight
                @include animation(fromRight .3s 1 ease)
            img
                max-width: 100%
                max-height: calc(100vh - 100px)
                height: auto
                flex-grow: 0
                flex-shrink: 1

@keyframes displayContent
    from
        transform: scale(.5)
        opacity: 0
    to
        transform: scale(1)
        opacity: 1

@keyframes fadeIn
    from
        opacity: 0
    to
        opacity: 1

@keyframes fadeOut
    from
        opacity: 1
    to
        opacity: 0

@keyframes fromLeft
    0%
        opacity: 0
        transform: translateX(-100px)
    100%
        opacity: 1
        transform: translateX(0)

@keyframes fromRight
    0%
        opacity: 0
        transform: translateX(100px)
    100%
        opacity: 1
        transform: translateX(0)
        