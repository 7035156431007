.bootstrap
    padding-top: 40px
    &--badges
        .badge
            margin: 0 4px
            &:first-child
                margin-left: 0
            &:last-child
                margin-right: 0
    &--buttons
        .button
            margin: 0 4px
            &:first-child
                margin-left: 0
            &:last-child
                margin-right: 0