.page-builder
    &--viewport
        border-radius: 10px
        background-color: lighten(color-theme('grey0'), 3)
        background-image: url(./img/viewport-background.png)
        background-size: 40px
        background-repeat: repeat
        background-position: 9px 9px
        border: 1px solid var(--neutral-200)
        box-shadow: inset 0 2px 8px transparentize(color-theme('grey0'), .6)
        transition: max-width .2s ease
        padding: 30px
        margin: 20px 0
        @include breakpoint(mobile)
            padding: 10px