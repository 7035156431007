// VARIABLES ----
@import "./tools/variables"

// RESET ----
@import "./tools/normalize"

// FONTS ----
@font-face 
    font-family: 'Montserrat'
    font-weight: 500
    src: url('../Fonts/montserrat/montserrat-regular-webfont.ttf') format('truetype'), url('../Fonts/montserrat/montserrat-regular-webfont.svg') format('svg')
@font-face
    font-family: 'Montserrat'
    font-weight: 600
    src: url('../Fonts/montserrat/montserrat-bold-webfont.ttf') format('truetype'), url('../Fonts/montserrat/montserrat-bold-webfont.svg') format('svg')

@font-face
    font-family: 'Gilroy'
    font-weight: 900
    font-style: normal
    src: url('../Fonts/gilroy/gilroy-black-webfont.woff2') format('woff2'), url('../Fonts/gilroy/gilroy-black-webfont.woff') format('woff')

@font-face
    font-family: 'Gilroy'
    font-weight: 900
    font-style: italic
    src: url('../Fonts/gilroy/gilroy-blackitalic-webfont.woff2') format('woff2'), url('../Fonts/gilroy/gilroy-blackitalic-webfont.woff') format('woff')

@font-face
    font-family: 'Gilroy'
    font-weight: 600
    font-style: normal
    src: url('../Fonts/gilroy/gilroy-bold-webfont.woff2') format('woff2'), url('../Fonts/gilroy/gilroy-bold-webfont.woff') format('woff')

@font-face
    font-family: 'Gilroy'
    src: url('../Fonts/gilroy/gilroy-bolditalic-webfont.woff2') format('woff2'), url('../Fonts/gilroy/gilroy-bolditalic-webfont.woff') format('woff')
    font-weight: 600
    font-style: italic

@font-face
    font-family: 'Gilroy'
    font-weight: 500
    font-style: normal
    src: url('../Fonts/gilroy/gilroy-medium-webfont.woff2') format('woff2'), url('../Fonts/gilroy/gilroy-medium-webfont.woff') format('woff')

@font-face
    font-family: 'Gilroy'
    src: url('../Fonts/gilroy/gilroy-mediumitalic-webfont.woff2') format('woff2'), url('../Fonts/gilroy/gilroy-mediumitalic-webfont.woff') format('woff')
    font-weight: 500
    font-style: italic

@font-face
    font-family: 'Gilroy'
    font-weight: 400
    font-style: normal
    src: url('../Fonts/gilroy/gilroy-regular-webfont.woff2') format('woff2'), url('../Fonts/gilroy/gilroy-regular-webfont.woff') format('woff')

@font-face
    font-family: 'Gilroy'
    src: url('../Fonts/gilroy/gilroy-regularitalic-webfont.woff2') format('woff2'), url('../Fonts/gilroy/gilroy-regularitalic-webfont.woff') format('woff')
    font-weight: 400
    font-style: italic

// UTILS ----
@import "./tools/animations"
@import "./tools/functions"
@import "./tools/grid"
@import "./tools/mixins"

@import "./modules/toastify"

// LAYOUT
@import "./modules/layout/badge.sass"
@import "./modules/layout/breadcrumb.sass"
@import "./modules/layout/footer.sass"
@import "./modules/layout/header.sass"
@import "./modules/layout/icons.sass"
@import "./modules/layout/list-page.sass"
@import "./modules/layout/modales.sass"
@import "./modules/layout/page.sass"
@import "./modules/layout/pagination.sass"
@import "./modules/layout/portlet.sass"
@import "./modules/layout/tabs.sass"
@import "./modules/layout/tags.sass"

// UTILS
@import "./modules/utils/cards.sass"
@import "./modules/utils/segment-controls.sass"
@import "./modules/utils/beautiful-dnd.sass"

// FILE MANAGER
@import "./modules/file-manager/file-manager-layout.sass"
@import "./modules/file-manager/folders.sass"
@import "./modules/file-manager/files.sass"

// SCAFFOLDING
@import "./modules/scaffolding/scaffolding-layout.sass"
@import "./modules/scaffolding/scaffolding-navigation.sass"

// LOGIN
@import "./modules/login/login.sass"
// TEXTS
@import "./modules/texts/headers.sass"
@import "./modules/texts/paragraphs.sass"
@import "./modules/texts/blockquotes.sass"
// FORMS
@import "./modules/forms/inputs.sass"
@import "./modules/forms/forms.sass"
@import "./modules/forms/buttons.sass"
@import "./modules/forms/search.sass"
// TABLES
@import "./modules/tables/basic-table.sass"
@import "./modules/tables/responsive-table.sass"
// DASHBOARD
@import "./modules/dashboard.sass"
// BOOTSTRAP
@import "./modules/bootstrap.sass"
// NAVIGATION
@import "./modules/navigation/navigation"
// ADMINISTRATORS
@import "./modules/administrators/administrator-list.sass"
@import "./modules/administrators/administrator-edit.sass"
// TOURNAMENTS
@import "./modules/tournaments/tournament-list.sass"
@import "./modules/tournaments/tournament-edit.sass"
// GAMES
@import "./modules/games/games.sass"
@import "./modules/games/game-icons.sass"
// PAGES
@import "./modules/pages/pages.sass"
// USERS
@import "./modules/users/users.sass"
// PAGE-BUILDER
@import "../../../PageBuilders/Infrastructure/Style/page-builder-layout"
@import "../../../PageBuilders/Infrastructure/Style/page-builder-settings"
@import "../../../PageBuilders/Infrastructure/Style/page-builder-components"
@import "../../../PageBuilders/Infrastructure/Style/page-builder-viewport"
@import "../../../PageBuilders/Infrastructure/Style/page-builder-page-content"

// FAQ
@import "./modules/faq/get-faq-post.sass"

@supports (padding-top: constant(safe-area-inset-top))
    body
        padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)

body
    font-family: 'Gilroy', sans-serif
    font-weight: 500
    font-size: 14px
    color: var(--default-text)
    background-color: var(--neutral-100)

#root
    min-height: 100vh
    display: flex
    flex-direction: column

a
    color: color-theme(primary)

hr
    height: 1px
    background-color: transparentize(color-theme('grey500'), .85)
    margin: 40px 0

header
    flex-grow: 0
    position: sticky
    z-index: 10
    right: 0
    left: 0
    top: 0

section.content
    height: auto
    flex-grow: 1

footer
    flex-grow: 0

.pb-0px
    padding-bottom: 0

.pb-10px
    padding-bottom: 10px !important

.pb-20px
    padding-bottom: 20px !important

.pb-30px
    padding-bottom: 30px !important