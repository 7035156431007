.viewport
    &--footer
        &-content
            background-color: var(--footer-color)
            padding: 40px 0
            &-columns
                display: flex
                flex-direction: row
                justify-content: space-between
                @include breakpointWeb('mobile')
                   flex-direction: column
                &-column
                    flex-grow: 1
                    padding: 0 10px
                    @include breakpointWeb('mobile')
                        padding: 20px 0
                    &:first-child
                        max-width: 300px
                        padding-left: 0
                        @include breakpointWeb('mobile')
                            max-width: inherit
                            padding-top: 0
                    &:last-child
                        max-width: 300px
                        padding-right: 0
                        @include breakpointWeb('mobile')
                            max-width: inherit
                            padding-bottom: 0
                    img
                        max-width: 128px
                        height: auto
                        display: block
                    svg
                        width: 128px
                        height: auto
                        fill: var(--text-color)
                    ul
                        li
                            a
                                color: var(--text-color)
                                &:hover
                                    color: var(--primary-color)
            &-title
                font-size: 14px
                font-weight: 600
                text-transform: uppercase 
                letter-spacing: 1.5px
                color: var(--text-color)
                padding-bottom: 10px
                @include breakpointWeb('mobile')
                    font-size: 16px
            &-subtitle
                font-size: 12px
                font-weight: 500 
                color: var(--text-color)
                padding-bottom: 20px
                opacity: .7
                @include breakpointWeb('mobile')
                    font-size: 14px
            &-info
                padding: 20px 0
                display: flex
                flex-direction: column
                &-address
                    font-size: 12px
                    color: var(--text-color)
                    opacity: .7
                    padding-bottom: 20px
                    @include breakpointWeb('mobile')
                        font-size: 14px
                &-email
                    a
                        width: auto
                        color: var(--text-color)
                        background-color: white
                        border-radius: 100px
                        box-shadow: 0 4px 12px rgba(0, 0, 0, .1)
                        border: 1px solid rgba(0, 0, 0, .1)
                        display: inline-block
                        padding: 12px 28px 14px
                        display: flex
                        flex-direction: row
                        align-items: center
                        justify-content: space-between
                        svg
                            width: 16px
                            height: 16px
                            fill: none
                            stroke: var(--text-color)
                            transition: all .2s ease
                        &:hover
                            svg
                                transform: translateX(8px)
        &-down
            color: var(--text-color)
            background-color: var(--footer-color)
            text-align: center
            position: relative
            padding: 20px 0
            opacity: .5
                