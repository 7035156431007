table
    width: 100%
    border-collapse: separate
    border-spacing: 0 10px
    thead
        tr
            th
                font-weight: 500
                text-align: left
                padding: 4px 10px 20px
                color: var(--neutral-500)
                &.is-actions-column
                    width: 140px
                    text-align: center
    tr
        td
            color: var(--default-text)
            background-color: var(--neutral-200)
            padding: 10px 14px
            margin: 10px 0
            .actions-buttons
                display: flex
                flex-direction: row
                justify-content: center
                .button
                    min-width: inherit
                    margin: 0 4px
                    &:first-child
                        margin-left: 0
                    &:last-child
                        margin-right: 0
            &:first-child
                border-top-left-radius: 6px
                border-bottom-left-radius: 6px
            &:last-child
                border-top-right-radius: 6px
                border-bottom-right-radius: 6px
        &:nth-child(odd)
            td
                background-color: var(--neutral-200)