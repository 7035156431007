.file-manager
    &--list
        &-folder
            width: 145px
            height: 180px
            background-color: var(--neutral-200)
            transition: all .2s ease
            transform: scale(1.0)
            border-radius: 10px
            overflow: hidden
            padding: 20px 12px 
            display: flex
            flex-direction: column
            justify-content: space-between
            margin-bottom: 20px
            margin-right: 20px
            .is-list-view &
                width: 100%
                height: auto
                flex-direction: row
                justify-content: flex-start
                align-items: center
                padding: 10px
                margin-right: 0
            &:hover
                background-color: var(--symbioze)
                transform: scale(1.05)
                .is-list-view &
                    transform: scale(1.01)
                .file-manager--list-folder-icon
                    background-color: transparentize(white, .5)
                    svg
                        fill: var(--neutral-200)
                .file-manager--list-folder-details-name
                    color: var(--neutral-200)
                .file-manager--list-folder-details-items
                    color: var(--neutral-200)
            &:active
                transform: scale(1.0)
            &-icon
                width: 42px
                height: 42px
                border-radius: 100px
                background-color: color-theme('grey200')
                transition: all .2s ease
                display: flex
                flex-direction: column
                justify-content: center
                align-items: center
                flex-grow: 0
                flex-shrink: 1
                .is-list-view &
                    width: 36px
                    height: 36px
                    margin-right: 10px
                svg
                    width: 24px
                    height: 24px
                    transition: all .2s ease
                    fill: color-theme('grey400')
                    display: block
                    .is-list-view &
                        width: 18px
                        height: 18px
            &-details
                display: flex
                flex-direction: column
                justify-content: flex-end
                flex-grow: 1
                .is-list-view &
                    flex-direction: row
                    justify-content: space-between
                    align-items: center
                &-name
                    font-size: 16px
                    font-weight: 600
                    color: color-theme('grey500')
                    transition: all .2s ease
                    padding-bottom: 10px
                    .is-list-view &
                        padding-bottom: 2px
                &-items
                    color: color-theme('grey400')
                    transition: all .2s ease