.footer
    @include breakpoint(mobile)
        display: none
    &--tools
        flex-basis: 200px
        flex-shrink: 0
        &-locales
            background-color: color-theme('grey300')
            border-radius: 4px
            border: 1px solid color-theme('grey0')
            box-shadow: inset 0 0 0 2px lighten(color-theme('grey300'), 10)
            transition: all .2s ease
            cursor: pointer
            position: relative
            display: flex
            flex-direction: row
            justify-content: flex-end
            align-items: center
            padding: 8px 16px
            &:hover
                border-color: var(--symbioze)
            svg
                width: 24px
                height: auto
                border-radius: 3px
                margin-left: 10px
            select
                opacity: 0
                cursor: pointer
                position: absolute
                bottom: 0
                right: 0
                left: 0
                top: 0
    &--down
        .grid-wrapper
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-between
            &:after,
            &:before
                display: none
        background-color: lighten(color-theme('grey0'), 5%)
        color: white
        padding: 10px 0
        .hint-text
            color: color-theme(primary)