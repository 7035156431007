input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="date"]
    width: 100%
    font-family: 'Gilroy', sans-serif
    font-size: 14px
    font-weight: 500
    background-color: color-theme('grey200')
    border: 1px solid color-theme('grey300')
    border-radius: 3px
    color: color-theme('grey500')
    padding: 9px 14px 10px
    box-shadow: 0 1px 2px transparentize(color-theme(grey0), .7)
    outline: none
    transition: all .3s ease
    appearance: none
    &:focus
        border-color: var(--symbioze)
    &::-webkit-input-placeholder,
    &:-moz-placeholder,
    &::placeholder
        opacity: .6
        color: color-theme(steel-grey)
    &.is-in-error
        border-color: red

input[disabled]
    opacity: .5

input[type="file"]
    width: 100%
    font-size: 14px
    font-weight: 500
    background-color: color-theme('grey200')
    border: 1px solid color-theme('grey300')
    box-shadow: 0 1px 2px transparentize(color-theme(grey0), .7)
    border-radius: 3px
    color: color-theme('grey500')
    padding: 9px 14px 10px
    outline: none
    transition: all .3s ease
    &::file-selector-button
        font-size: 10px
        font-weight: 600
        color: var(--neutral-200)
        text-transform: uppercase
        cursor: pointer
        border: none
        border-radius: 3px
        background-color: color-theme('primary')
        margin-right: 8px

select
    width: 100%
    background-color: color-theme('grey200')
    border: 1px solid color-theme('grey300')
    font-size: 14px
    font-weight: 500
    color: color-theme('grey400')
    padding: 10px 50px 11px 14px
    box-shadow: 0 1px 2px transparentize(color-theme(grey0), .7)
    outline: none
    border-radius: 3px
    transition: all .3s ease
    appearance: none
    &:focus
        border-color: var(--symbioze)
    &::-webkit-input-placeholder,
    &:-moz-placeholder,
    &::placeholder
        opacity: .15
        text-transform: uppercase
    &[disabled]
        opacity: .5

input[type="checkbox"]
    width: 40px
    height: 22px
    border-radius: 22px
    appearance: none
    background-color: color-theme('grey200')
    transition: all .2s ease
    position: relative
    &:after
        content: ''
        width: 18px
        height: 18px
        border-radius: 18px
        background-color: color-theme('grey400')
        box-shadow: 0 2px 8px transparentize(color-theme('grey0'), .5)
        transition: all .2s ease
        position: absolute
        z-index: 1
        left: 2px
        top: 2px
    &:checked
        background-color: var(--symbioze)
        &:after
            left: 20px
            background-color: color-theme('grey500')

input[type="radio"]
    width: 24px
    height: 24px
    border-radius: 24px
    border: 1px solid #c0c3cb
    background-color: color-theme(grey100)
    margin-right: 8px
    display: inline-block
    cursor: pointer
    vertical-align: top
    transition: all .2s ease
    box-shadow: 0 2px 2px rgba(0, 0, 0, .1)
    -webkit-appearance: none
    -moz-appearance: none
    -ms-appearance: none
    position: relative
    overflow: hidden
    &:before
        content: ''
        width: 24px
        height: 24px
        transition: all .2s ease
        transform: scale(0)
        background-color: var(--symbioze)
        border-radius: 24px
        opacity: 1
        position: absolute
        z-index: 1
        left: 50%
        top: 50%
        margin-left: -12px
        margin-top: -12px
    &:after
        content: ''
        width: 8px
        height: 8px
        border-radius: 8px
        background-color: white
        position: absolute
        z-index: 2
        left: 50%
        top: 50%
        margin-left: -4px
        margin-top: -4px
        z-index: 2
    &:checked
        border-color: var(--symbioze)
        &:after
            background-color: var(--neutral-200)
        &:before
            transform: scale(1)
    & + label
        width: auto
        font-size: 14px
        font-weight: 500
        color: color-theme(secondary)
        cursor: pointer
        user-select: none
        display: inline-block
        margin-bottom: 0
        margin-right: 16px
        &:last-child
            margin-right: 0

input[type="range"]
    appearance: none
    height: 3px
    border-radius: 3px
    background-color: var(--neutral-200)
    background-image: linear-gradient(var(--symbioze), var(--symbioze))
    background-size: var(--rangeTrackSize) 100%
    background-repeat: no-repeat
    &::-webkit-slider-thumb
        appearance: none
        width: 12px
        height: 12px
        border-radius: 12px
        background-color: var(--symbioze)
        border: 2px solid color-theme('grey200')

textarea
    width: 100%
    resize: vertical
    font-family: 'Gilroy', sans-serif
    font-size: 14px
    font-weight: 500
    background-color: color-theme('grey200')
    border: 1px solid color-theme('grey300')
    box-shadow: 0 1px 2px transparentize(color-theme(grey0), .7)
    border-radius: 3px
    color: color-theme('grey500')
    padding: 10px 14px 11px
    outline: none
    transition: all .3s ease
    &:focus
        border-color: var(--symbioze)

.input
    width: 100%
    max-width: 600px
    margin: 0 auto
    padding: 0 0 30px 0
    &.has-no-margin
        padding: 0
    &.is-disabled
        opacity: .7
    &--group
        padding-bottom: 50px
        border-bottom: 1px solid color-theme('grey200')
        margin-bottom: 30px
        &:last-child
            border: none
            margin-bottom: 0
        &-name
            font-size: 14px
            font-weight: 500
            text-align: center
            color: color-theme('grey400')
            padding-bottom: 10px
        .input
            &:last-child
                padding-bottom: 0
    &--container
        background-color: color-theme('grey200')
        border: 1px solid color-theme('grey300')
        transition: all .2s ease
        border-radius: 4px
        box-shadow: 0 1px 2px transparentize(color-theme(grey0), .7)
        padding: 8px 12px
        position: relative
        display: flex
        flex-direction: column
        &.is-checkbox
            background-color: transparent
            padding: 0
            box-shadow: none
            border: none
            border-radius: none
            flex-direction: row
            justify-content: space-between
            align-items: center
            label
                margin-bottom: 0
            input
                width: 40px
                border-radius: 22px
        .is-focused &
            border-color: var(--symbioze)
            box-shadow: 0 0 0 3px rgba(var(--symbioze-rgb), .2) 
        label
            flex-basis: inherit !important
            font-size: 12px
            color: color-theme('grey400')
            transition: all .2s ease
            flex-grow: 0
            padding: 0
            margin-bottom: 4px
            .is-focused &
                color: color-theme('grey500')
        input
            width: 100%
            border: none
            box-shadow: none
            padding: 0
            border-radius: 0
        select
            width: 100%
            border: none
            box-shadow: none
            padding: 0
            border-radius: 0
        textarea
            padding: 0
            border: none
            box-shadow: none
            border-radius: 0
    &--text
    &--select
        select
            color: color-theme('grey500')
        &:after
            content: ''
            width: 6px
            height: 6px
            pointer-events: none
            border-left: 1.5px solid color-theme('grey400')
            border-bottom: 1.5px solid color-theme('grey400')
            position: absolute
            right: 15px
            top: 50%
            transform: rotate(-45deg)
            margin-top: -6px
    &--checkbox
        height: 22px
    &--textarea
        margin-top: 2px
        padding-top: 8px
        border-top: 1px solid color-theme('grey300')
    &--color-picker
        display: flex
        flex-direction: row
        align-items: center
        &:before
            content: '#'
            font-size: 12px
            color: color-theme('grey400')
            display: block
            margin-right: 6px
            padding-top: 1px
        &-list
            display: flex
            flex-direction: row
            margin-top: 10px
            padding: 0 2px
            &-item
                width: 18px
                height: 18px
                background-color: color-theme('grey0')
                border: 1px solid var(--neutral-200)
                box-shadow: 0 0 0 2px color-theme('grey200')
                cursor: pointer
                transition: all .2s ease
                border-radius: 4px
                margin: 0 4px
                &.is-active
                    box-shadow: 0 0 0 2px var(--symbioze)
                &:first-child
                    margin-left: 0
                &:last-child
                    margin-right: 0
    &--additional-information
        background-color: transparent
        color: color-theme('grey400')
        border-left: none
        margin-top: 10px
        svg
            stroke: color-theme('grey400')