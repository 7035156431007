.page-builder
    &--content
        margin-bottom: 20px
        .portlet
            background-color: var(--neutral-200)
            border: 1px solid color-theme('grey200')
            box-shadow: 0 2px 8px transparentize(color-theme('grey0'), .6)
            transition: box-shadow .2s ease
            border-radius: 8px
            margin: 0
            &.is-selected
                box-shadow: 0 0 0 2px var(--symbioze)
            &--title
                background-color: color-theme('grey200')
                border-top-left-radius: 8px
                border-top-right-radius: 8px
                padding: 0 16px
                &-label
                    font-size: 12px
                    font-weight: 600
                    color: color-theme('grey400')
                    line-height: 36px
                    svg
                        width: 18px
                        height: 18px
                        stroke: color-theme('grey400')
                        fill: none
                &-toolbar
                    .button
                        padding: 4px 10px 5px
                        svg
                            stroke: color-theme('grey500')
                            margin-right: 0
        &-placeholder
            width: 100%
            background-color: transparent
            border: 2px dashed color-theme('grey300')
            border-radius: 8px
            display: flex
            flex-direction: column
            align-items: center
            padding: 20px
            svg
                width: 24px
                height: 24px
                fill: color-theme('grey300')
                margin-bottom: 20px
            p
                font-size: 14px
                font-weight: 500
                color: color-theme('grey400')
                text-align: center
        &-two-columns,
        &-three-columns
            .grid-col-50p
                padding: 0 14px
                &:first-child
                    padding-left: 0
                &:last-child
                    padding-right: 0
        &-slideshow,
        &-gallery
            display: flex
            flex-direction: row
            justify-content: space-between
            &.is-vertical
                flex-direction: column
            &-image
                width: 70px
                .is-vertical &
                    width: 100%
                    margin-bottom: 10px
                img
                    width: 70px
                    height: 70px
                    border-radius: 5px
                    display: block
                    margin: 0 auto
            &-form
                width: calc(100% - 88px)
                .is-vertical &
                    width: 100%
            &-add-form
                padding-top: 20px
                .form--group-input
                    display: flex
                    flex-direction: row
                    align-items: center
                    .is-vertical &
                        display: block
                    input
                        flex-grow: 0
                    button
                        min-width: 100px
                        height: 41px
                        margin-left: 10px
                        flex-grow: 1
                        .is-vertical &
                            width: 100%
                            margin-left: 0
                            margin-top: 10px
            &-empty
                border-radius: 4px
                border: 2px dashed color-theme('grey300')
                padding: 10px
                display: flex
                flex-direction: column
                align-items: center
                margin-bottom: 10px
                p
                    font-size: 12px
                    color: color-theme('grey400')
                svg
                    width: 24px
                    fill: color-theme('grey300')
        &-accordion
            &-empty
                border-radius: 4px
                border: 2px dashed color-theme('grey300')
                padding: 10px
                display: flex
                flex-direction: column
                align-items: center
                margin-bottom: 10px
                p
                    font-size: 12px
                    color: color-theme('grey400')
                svg
                    width: 24px
                    fill: color-theme('grey300')
            &-add-form
                padding-top: 20px
                .form--group
                    padding: 0
        &-contact-requests
            &-add-form
                padding-top: 20px
        &-inline-wysiwyg
            .mce-content-body
                background-color: white
                border-radius: 4px
                padding: 16px
                h1,
                h2,
                h3,
                h4,
                h5,
                h6
                    color: var(--neutral-200)
                p
                    color: var(--neutral-200)
                ol,
                ul
                    padding-left: 20px
                ol li,
                ul li
                    color: var(--neutral-200)
                img
                    max-width: 100%
                    height: auto
            &.is-dark
                .mce-content-body
                    background-color: color-theme('grey0')
                    h1,
                    h2,
                    h3,
                    h4,
                    h5,
                    h6
                        color: color-theme('grey500')
                    p
                        color: color-theme('grey500')
                    ol li,
                    ul li
                        color: color-theme('grey500')
            &-dirty-state
                padding-top: 10px
                margin-bottom: 10px



// TinyMCE
.tox 
    .tox-menubar
        background: var(--neutral-200) !important
    .tox-toolbar
        background: var(--neutral-200) !important
    .tox-toolbar__primary
        background: var(--neutral-200) !important
    .tox-tbtn:hover,
    .tox-mbtn:hover:not(:disabled):not(.tox-mbtn--active)
        background: var(--symbioze) !important
        color: var(--neutral-200) !important
        svg
            fill: var(--neutral-200) !important
    .tox-menu
        background: var(--neutral-200) !important
    .tox-collection--list
        .tox-collection__item--active
            background: var(--symbioze) !important
            color: var(--neutral-200) !important
            svg
                fill: var(--neutral-200) !important
    .tox-statusbar
        background: var(--neutral-200) !important

.tox-notifications-container
    display: none
.tox-statusbar__branding
    display: none