.scaffolding
    padding: 30px 0
    &--header
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center
        margin-bottom: 20px
        h2
            margin: 0
            padding: 0
        &-actions

    &--columns
        display: flex
        flex-direction: row
        &-navigation
            width: 300px
            padding-right: 10px
        &-content
            width: calc(100% - 300px)
            padding-left: 10px