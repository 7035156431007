.breadcrumb
    padding: 2px 0 3px
    background-color: lighten(color-theme('grey0'), 5%)
    @include breakpoint(mobile)
        overflow-x: auto
    ol
        list-style: none
        li
            font-size: 10px
            text-transform: uppercase
            letter-spacing: 1px
            color: darken(white, 60%)
            display: inline-block
            margin: 0 5px
            &:after
                content: '>'
                margin-left: 10px
            &:first-child
                margin-left: 0
            &:last-child
                margin-right: 0
                &:after
                    display: none
            a
                color: color-theme('grey400')
                transition: all .3s ease
                &:hover
                    color: var(--symbioze)