.page-builder
    &--settings
        display: flex
        flex-direction: column
        &::-webkit-scrollbar
            display: none
        *
            &::selection
                display: none
            input
                &::selection
                    opacity: 1
                    background-color: color-theme('primary')
                    color: var(--neutral-200)
                    display: block
        &-title
            font-size: 14px
            font-weight: 500
            color: color-theme('grey400')
            display: flex
            align-items: center
            padding: 20px
            border-bottom: 1px solid color-theme('grey200')
            svg
                width: 24px
                height: 24px
                stroke: color-theme('grey300')
                margin-right: 8px
        &-list
            padding-bottom: 20px
            .form
                &--group
                    border-bottom: none
                    padding: 4px 0
                    label
                        font-size: 12px
                        flex-basis: 100%
                        flex-shrink: 1
                    &-input
                        display: flex
                        align-items: center
                        &.is-color-picker
                            flex-direction: column
                            align-items: flex-start
                            input
                                width: 100%
                                max-width: inherit
                    &:first-child
                        padding-top: 0
                    &:last-child
                        padding-bottom: 0
                    &-range
                        .form--group-input
                            min-width: 120px
        &-please-select
            padding: 20px
            display: flex
            flex-direction: column
            align-items: center
            &-icon
                width: 24px
                padding-bottom: 12px
                display: inline-block
                svg
                    width: 24px
                    height: 24px
                    fill: color-theme('grey300')
            p
                font-size: 12px
                font-weight: 500
                line-height: 18px
                color: color-theme('grey300')
                text-align: center
        &-locales
            margin-top: auto
            padding: 20px 40px 0 40px