.grid-wrapper,
.grid-row {
	*zoom: 1;
}
.grid-wrapper:before,
.grid-row:before,
.grid-wrapper:after,
.grid-row:after {
	content: '';
	display: table;
}
.grid-wrapper:after, .grid-row:after {
	clear: both;
}
.grid-wrapper {
	width: 100%;
	max-width: $website-max-width;
	margin: 0 auto;
	padding: 0 20px;
}
.grid-row {
	margin-bottom: 10px;
}
[class*="grid-col"] {
	margin: 0;
	display: inline-block;
	vertical-align: top;
}
.grid-col-1 {
	width: 5.55556%;
}
.grid-col-2 {
	width: 11.11111%;
}
.grid-col-3 {
	width: 16.66667%;
}
.grid-col-4 {
	width: 22.22222%;
}
.grid-col-5 {
	width: 27.77778%;
}
.grid-col-6 {
	width: 33.33333%;
}
.grid-col-7 {
	width: 38.88889%;
}
.grid-col-8 {
	width: 44.44444%;
}
.grid-col-9 {
	width: 50%;
}
.grid-col-10 {
	width: 55.55556%;
}
.grid-col-11 {
	width: 61.11111%;
}
.grid-col-12 {
	width: 66.66667%;
}
.grid-col-13 {
	width: 72.22222%;
}
.grid-col-14 {
	width: 77.77778%;
}
.grid-col-15 {
	width: 83.33333%;
}
.grid-col-16 {
	width: 88.88889%;
}
.grid-col-17 {
	width: 94.44444%;
}
.grid-col-18 {
	width: 100%;
}
.grid-col-10p {
	width: 10%;
}
.grid-col-20p {
	width: 20%;
}
.grid-col-40p {
	width: 40%;
}
.grid-col-25p {
	width: 25%;
}
.grid-col-50p {
	width: 50%;
}
.grid-col-60p {
	width: 60%;
}
.grid-col-75p {
	width: 75%;
}

@media (max-width: $mobile-max-width) {
	.grid-wrapper {
		padding: 0 10px;
	}
	.mb-grid-col-1 {
		width: 5.55556%;
	}
	.mb-grid-col-2 {
		width: 11.11111%;
	}
	.mb-grid-col-3 {
		width: 16.66667%;
	}
	.mb-grid-col-4 {
		width: 22.22222%;
	}
	.mb-grid-col-5 {
		width: 27.77778%;
	}
	.mb-grid-col-6 {
		width: 33.33333%;
	}
	.mb-grid-col-7 {
		width: 38.88889%;
	}
	.mb-grid-col-8 {
		width: 44.44444%;
	}
	.mb-grid-col-9 {
		width: 50%;
	}
	.mb-grid-col-10 {
		width: 55.55556%;
	}
	.mb-grid-col-11 {
		width: 61.11111%;
	}
	.mb-grid-col-12 {
		width: 66.66667%;
	}
	.mb-grid-col-13 {
		width: 72.22222%;
	}
	.mb-grid-col-14 {
		width: 77.77778%;
	}
	.mb-grid-col-15 {
		width: 83.33333%;
	}
	.mb-grid-col-16 {
		width: 88.88889%;
	}
	.mb-grid-col-17 {
		width: 94.44444%;
	}
	.mb-grid-col-18 {
		width: 100%;
	}
	.mb-grid-no-padding {
		padding: 0 !important;
	}
}