.page-builder
    min-height: 100vh
    display: flex
    flex-direction: column
    &--header
        background-color: var(--neutral-200)
        border-bottom: 1px solid color-theme('grey200')
        padding: 9px 20px
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center
        flex-grow: 0
        flex-shrink: 0
        &-logo
            height: 28px
            flex-grow: 1
            flex-shrink: 0
            @include breakpoint(mobile)
                display: none
            svg
                width: 158px
                height: auto
                cursor: pointer
        &-logo
            &-mobile
                height: 26px
                flex-grow: 1
                flex-shrink: 0
                display: none
                @include breakpoint(mobile)
                    display: block
                svg
                    width: 26px
                    height: 26px
        &-breakpoint-switcher
            flex-grow: 1
            flex-shrink: 0
            display: flex
            justify-content: center
            @include breakpoint(mobile)
                .segment
                    input[type=radio] + label
                        font-size: 0
                        padding: 5px 14px 6px
                        svg
                            margin-right: 0
        &-profile
            flex-grow: 1
            flex-shrink: 0
            align-items: flex-end
            display: flex
            flex-direction: column
            &-user
                width: auto
                display: inline-flex
                align-items: center
                color: color-theme('grey400')
                span
                    @include breakpoint(mobile)
                        display: none
                &-avatar
                    width: 28px
                    height: 28px
                    font-size: 14px
                    font-weight: 600
                    color: var(--neutral-200)
                    border-radius: 28px
                    background-color: var(--symbioze)
                    margin-left: 10px
                    display: flex
                    align-items: center
                    justify-content: center
                    @include breakpoint(mobile)
                        width: 26px
                        height: 26px
                        font-size: 12px
                        margin-left: 0
    &--main
        display: flex
        flex-direction: row
        flex-grow: 1
        @include breakpoint(mobile)
            overflow-x: hidden
        &-content
            max-height: calc(100vh - 52px)
            overflow-y: auto
            background-color: color-theme('app-background')
            flex-grow: 1
            @include breakpoint(mobile)
                width: 100vw
                overflow-x: hidden
                flex-shrink: 0
            &::-webkit-scrollbar
                display: none
            &-page
                padding-bottom: 30px
    &--settings
        max-height: calc(100vh - 52px)
        width: 270px
        background-color: var(--neutral-200)
        border-right: 1px solid color-theme('grey200')
        padding-bottom: 20px
        overflow-y: auto
        flex-grow: 0
        flex-shrink: 0
        @include breakpoint(mobile)
            transition: all .2s ease
            transform: translateX(-100vw)
            position: absolute
            z-index: 2
            left: 0
    &--components
        max-height: calc(100vh - 52px)
        width: 270px
        background-color: var(--neutral-200)
        border-left: 1px solid color-theme('grey200')
        padding-bottom: 20px
        overflow-y: auto
        flex-grow: 0
        flex-shrink: 0
        position: relative
        @include breakpoint(mobile)
            transition: all .2s ease
            transform: translateX(100vw)
            position: relative
            z-index: 2
            right: 0
    &--contextual
        &-group
            transition: all .2s ease
            padding: 0 20px 0 20px
            border-bottom: 1px solid color-theme('grey200')
            &-title
                font-size: 14px
                font-weight: 500
                color: color-theme('grey500')
                background-color: var(--neutral-200)
                -webkit-backdrop-filter: blur(8px)
                backdrop-filter: blur(8px)
                cursor: pointer
                display: flex
                flex-direction: row
                align-items: center
                margin-left: -20px
                margin-right: -20px
                padding: 10px 20px 10px 20px
                position: sticky
                z-index: 2
                top: 0
                svg
                    width: 24px
                    height: 24px
                    fill: color-theme('grey400')
                    margin-left: -6px
                    transition: all .2s ease
                    transform: rotate(0deg)
                    .is-hidden &
                        transform: rotate(180deg)
            &-item
                padding-top: 20px
                padding-bottom: 10px
                &-label
                    font-size: 12px
                    font-weight: 500
                    color: color-theme('grey400')
                    padding: 0 2px 10px 2px
                .controls-container
                    .controls
                        width: 100%
            &-content
                --group-content-height: auto
                height: var(--group-content-height)
                transition: all .2s ease
                overflow: hidden