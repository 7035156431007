.file-manager
    &--toolbar
        background-color: var(--neutral-200)
        -webkit-backdrop-filter: blur(10px)
        backdrop-filter: blur(10px)
        border-radius: 10px
        padding: 8px
        margin-bottom: 20px
        display: flex
        flex-direction: row
        justify-content: space-between
        &> div
            flex-grow: 0
            flex-shrink: 0
        .button
            margin-right: 10px
            &:last-child
                margin-right: 0
            svg
                margin-right: 0
            &.is-small
                padding: 6px 10px
    &--list
        display: flex
        flex-direction: row
        flex-wrap: wrap
        justify-content: left
        margin-right: -25px
        &.is-list-view
            margin-right: 0