.navigation
    &--columns
        display: flex
        flex-direction: row
        align-content: space-between
        @include breakpoint(mobile)
            flex-direction: column
        &-left
            width: 450px
            flex-grow: 0
            flex-shrink: 0
            @include breakpoint(mobile)
                width: initial
            .portlet
                &--title
                    &-toolbar
                        .is-navigation-items-opener
                            display: none
                            @include breakpoint(mobile)
                                display: block
                            svg
                                margin-right: 0
                                stroke: color-theme('grey500')
        &-right
            flex-grow: 1
            margin-left: 14px
            @include breakpoint(mobile)
                margin-left: 0
            form
                .button
                    @include breakpoint(mobile)
                        width: 100%

    &--tree
        &.is-loading
            opacity: .5
    &--modal
        width: 700px
        padding: 20px 40px
        @include breakpoint(mobile)
            width: initial
            padding: 20px

    // SortableTree theme
    .rst__tree
        min-height: 400px
    .rstcustom
        &__row
            width: 100%
            &Label
                flex-grow: 1
            &:hover
                .rstcustom__toolbarButton
                    .button
                        opacity: 1
        &__toolbar
            &Button
                .button
                    opacity: 0
                    transition: all .1s ease
            