.pages
    &--images
        width: 100%
        background-color: color-theme('grey200')
        border: 1px solid color-theme('grey300')
        border-radius: 6px
        margin-top: 4px
        display: flex
        flex-direction: row
        justify-content: stretch
        align-items: center
        &-picture
            padding: 6px
            border-right: 1px solid color-theme('grey300')
            flex-grow: 0
            flex-shrink: 0
            img
                width: 32px
                height: 32px
                border-radius: 3px
                display: block
        &-info
            font-size: 12px
            font-weight: 500
            color: color-theme('grey500')
            border-right: 1px solid color-theme('grey300')
            flex-grow: 1
            flex-shrink: 1
            overflow: hidden
            padding: 6px
        &-delete
            flex-grow: 0
            display: flex
            padding: 6px
            svg
                width: 24px
                height: auto
                stroke: white
            &:hover
                cursor: pointer
                svg
                    stroke: color-theme('primary')