/* ================= */
/* Extra Modal
/* ================= */
.tournament
    &--modale
        &-choose-type
            min-width: 400px
            padding: 30px 30px 20px
            @include breakpoint(mobile)
                min-width: inherit
            .dashboard-card--icon
                width: 32px
                height: 32px
                svg
                    width: 100%
                    height: auto
                    transform: scale(1.1)
                    *
                        fill: white
            .dashboard-card
                &:hover
                    svg
                        *
                            fill: color-theme(primary)