.selected-item
    text-align: center

.controls-container
    --highlight-width: auto
    --highlight-x-pos: 0
    display: flex
    flex-direction: column
    align-items: center
    *
        &::selection
            display: none

.controls
    justify-content: space-between
    background: var(--neutral-200)
    border-radius: 10px
    padding: 2px
    position: relative
    display: flex
    .is-dark-mode &
        background: var(--neutral-300)
        border: 1px solid var(--neutral-400)
        box-shadow: 0 1px 2px rgba(var(--neutral-100-rgb), .3)
    .is-black-mode &
        background: var(--neutral-300)
    input[type="radio"]
        opacity: 0
        margin: 0
        top: 0
        right: 0
        bottom: 0
        left: 0
        position: absolute
        width: 100%
        cursor: pointer
        height: 100%
    &:before
        content: ''
        background: var(--neutral-100)
        border-radius: 8px
        width: var(--highlight-width)
        transform: translateX(var(--highlight-x-pos))
        transition: transform .2s cubic-bezier(.17,.67,.58,1.24), width .2s ease
        position: absolute
        top: 2px
        bottom: 2px
        left: 0
        z-index: 0
        .is-dark-mode &
            background: var(--symbioze)
        .is-black-mode &
            background: var(--neutral-100)

.segment
    position: relative
    text-align: center
    z-index: 1
    flex-grow: 1
    flex-shrink: 1
    input[type="radio"]
        &+ label
            height: 100%
            font-size: 12px
            font-weight: 600
            color: var(--neutral-500)
            cursor: pointer
            transition: color .3s ease
            padding: 5px 20px 6px
            display: flex
            align-items: center
            .is-dark-mode &
                color: var(--default-text)
            .is-black-mode &
                color: var(--default-text)
            svg
                width: 16px
                height: 16px
                transition: stroke .3s ease
                stroke: color-theme('grey500')
                display: inline-block
                vertical-align: -4px
                margin-right: 4px
                *
                    stroke-width: 2px
    &.active
        input[type="radio"]
            &+ label
                color: var(--symbioze)
                .is-dark-mode &
                    color: var(--neutral-100)
                .is-black-mode &
                    color: var(--symbioze)
                svg
                    stroke: var(--symbioze)
                    .is-dark-mode &
                        stroke: var(--neutral-100)
                    .is-black-mode &
                        stroke: var(--symbioze)