.page-builder
    &--components
        &::-webkit-scrollbar
            display: none
        *
            &::selection
                display: none
        &-title
            font-size: 14px
            font-weight: 500
            color: color-theme('grey400')
            display: flex
            align-items: center
            padding: 20px
            border-bottom: 1px solid color-theme('grey200')
            svg
                width: 24px
                height: 24px
                fill: color-theme('grey300')
                margin-right: 8px
        &-list
            display: flex
            flex-direction: row
            align-items: flex-start
            gap: 10px
            padding-top: 10px
            padding-bottom: 20px
            &-item
                width: 70px
                display: flex
                flex-direction: column
                &-icon
                    width: 70px
                    height: 70px
                    background-color: color-theme('grey200')
                    border: 1px solid color-theme('grey300')
                    border-radius: 8px
                    box-shadow: 0 1px 2px transparentize(color-theme('grey0'), .7)
                    cursor: grab
                    display: flex
                    flex-direction: column
                    align-items: center
                    justify-content: center
                    transition: all .2s ease
                    transform: translateY(0)
                    &:hover
                        box-shadow: 0 14px 14px transparentize(color-theme('grey0'), .85)
                        transform: translateY(-8px)
                    svg
                        width: 32px
                        height: 32px
                        stroke: color-theme('grey400')
                &-type
                    font-size: 12px
                    font-weight: 500
                    color: color-theme('grey400')
                    cursor: default
                    text-align: center
                    padding-top: 10px
