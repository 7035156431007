.table-responsive
    @include breakpoint(mobile)
        width: 100%
        overflow-x: auto
        -webkit-overflow-scrolling: touch
        display: block
        table
            min-width: 800px
            tr
                td
                    *
                        overflow-wrap: break-word