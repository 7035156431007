.button
    font-family: 'Montserrat', sans-serif
    font-size: 12px
    font-weight: 600
    letter-spacing: .5px
    min-width: 150px
    color: white
    background-color: color-theme('grey300')
    transition: all .2s ease
    text-align: center
    cursor: pointer
    border-radius: 3px
    display: inline-block
    padding: 8px 20px
    position: relative
    overflow: hidden
    &:after
        content: ''
        transition: all .2s ease
        background-color: transparentize(white, .9)
        position: absolute
        bottom: 0
        right: 50%
        left: 50%
        top: 0
    &:hover
        &:after
            right: 0
            left: 0
    &:active
        transform: scale(0.95)
    .material-icons
        font-size: 14px
        vertical-align: -1px
    svg
        width: 15px
        stroke: color-theme('grey500')
        vertical-align: -3px
        margin-right: 4px
    &.is-loading
        &:before
            content: ''
            width: 10px
            height: 10px
            border: 2px solid rgba(255, 255, 255, .3)
            border-top: 2px solid white
            border-radius: 100%
            @include animation(spin 1s infinite linear)
            display: inline-block
            vertical-align: 1px
            margin-bottom: -3px
            margin-right: 6px
    &.is-small
        min-width: auto
        font-size: 12px
        padding: 6px 16px
        .material-icons
            font-size: 12px
            vertical-align: -2px
    &.is-full-width
        width: 100%

// STYLE
.button
    &.is-primary
        background-color: color-theme(primary)
        color: var(--neutral-200)
        &:after
            background-color: transparentize(color-theme(primary), .9)
        &.is-loading
            &:before
                border-color: var(--neutral-200)
                border-top-color: var(--neutral-200)
        &.is-transparent
            background-color: transparent
            color: color-theme(primary)
            svg
                stroke: color-theme(primary)
        &.is-underline
            text-decoration: underline
        &.is-outlined
            background-color: transparent
            border: 1px solid color-theme(primary)
            color: color-theme(primary)
            svg
                stroke: color-theme(primary)
    &.is-success
        background-color: color-theme(success)
        color: white
    &.is-error
        background-color: color-theme(error)
        color: white
    &.is-red
        background-color: color-theme('red')
        color: white
        &.is-transparent
            background-color: transparent
            color: color-theme('red')
        &.is-outlined
            background-color: transparent
            border: 1px solid color-theme('red')
            color: color-theme('red')
            svg
                stroke: color-theme('red')
            &:hover
                background-color: color-theme('red')
                color: color-theme('grey500')
                svg
                    stroke: color-theme('gre500')
    &.is-disabled
        opacity: .4
        cursor: initial