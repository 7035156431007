/* ---------------- */
/* MODALES
/* ---------------- */
.modal
    display: none
    position: fixed
    z-index: 50
    bottom: 0
    right: 0
    left: 0
    top: 0
    &.is-opened
        display: block
    &--inner
        --safe-area-inset-bottom: env(safe-area-inset-bottom)
        padding-bottom: var(--safe-area-inset-bottom)
        position: absolute
        z-index: 100
        left: 50%
        top: 50%
        transform: translate3D(-50%, -50%, 0)
        @include breakpoint(mobile)
            transform: none
            left: 0
            top: 0
    &--container
        max-height: 90vh
        background-color: var(--neutral-200)
        overflow-x: hidden
        overflow-y: auto
        border-radius: 10px
        box-shadow: 0 4px 20px transparentize(color-theme('grey0'), .7)
        transition: all .2s ease
        display: flex
        flex-direction: column
        @include breakpoint(mobile)
            width: 100vw
            height: 100vh
            max-height: inherit
            border-radius: 0
        .is-shownFromUp &
            @include animation(showFromUp .3s 1 ease)
        .is-shownFromBottom &
            @include animation(showFromBottom .3s 1 ease)
    &--title
        padding: 20px 40px 19px
        position: relative
        @include breakpoint(mobile)
            position: sticky
            top: 0
        h2
            font-size: 18px
            font-weight: 500
            color: white
            text-align: center
            text-transform: uppercase
            padding: 0
            @include breakpoint(mobile)
                font-size: 16px
        &-close
            width: 36px
            height: 36px
            background-color: transparent
            position: absolute
            right: 20px
            top: 50%
            cursor: pointer
            outline: none
            margin-top: -18px
            @include breakpoint(mobile)
                font-size: 20px
                right: 10px
            &:before
                content: ''
                width: 2px
                height: 16px
                background-color: color-theme('grey400')
                transform: rotate(-45deg)
                display: inline-block
                position: absolute
                left: 50%
                top: 50%
                margin-top: -8px
            &:after
                content: ''
                width: 2px
                height: 16px
                background-color: color-theme('grey400')
                transform: rotate(45deg)
                display: inline-block
                position: absolute
                left: 50%
                top: 50%
                margin-top: -8px
        &.red-title
            background-color: color-theme(g4g-primary)
    &--body
        display: flex
        flex-direction: column
        flex-grow: 1
    &--confirmation
        max-width: 400px
        display: flex
        flex-direction: column
        flex-grow: 1
        &-title
            font-size: 16px
            font-weight: 600
            color: color-theme('primary')
            text-align: center
            padding: 10px 20px 40px
            @include breakpoint(mobile)
                font-size: 14px
    &--information
        min-width: 320px
        text-align: center
        padding: 8px 30px
    &--actions
        width: 100%
        padding: 10px 30px 30px
        display: flex
        flex-direction: column
        flex-grow: 1
        justify-content: flex-end
        .button
            margin-bottom: 10px
            &:last-child
                margin-bottom: 0
    &--icon
        width: 44px
        height: 44px
        background-color: color-theme('grey200')
        border-radius: 44px
        text-align: center
        margin: 12px auto 0
        padding: 10px
        svg
            width: 24px
            height: 24px
            stroke: color-theme('grey500')
    &--overlay
        position: fixed
        z-index: 29
        top: 0
        left: 0
        right: 0
        bottom: 0
        background-color: transparentize(color-theme('grey0'), .4)
        -webkit-backdrop-filter: blur(8px)
        backdrop-filter: blur(8px)
        transition: all .2s ease
        display: none
        .is-opened &
            display: block
            @include animation(showOverlay .3s 1 ease)

    &--form
        &-edit
            width: 100%
            max-height: calc(100vh - 90px)
            height: 100vh
            transform: none
            bottom: 0
            left: auto
            top: auto
            .modal--container
                height: calc(100vh - 90px)
                max-height: initial
                border-bottom-left-radius: 0
                border-bottom-right-radius: 0