.page
    &--header
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center
        padding-top: 30px
        padding-bottom: 20px
        h1,
        h2,
        h3
            margin: 0
            padding: 0
    &--actions
        display: flex
        flex-direction: row
        .button
            margin-right: 14px
            &:last-child
                margin-right: 0
    
    &--columns
        display: flex
        flex-direction: row
        justify-content: space-between
        padding-top: 30px
        padding-bottom: 20px
        &-left
            width: calc(100% - 320px)
        &-right
            width: 300px
            border-left: 1px solid lighten(color-theme(dark-grey), 10)
            padding-left: 20px

    &--content
        .form
            &--group
                &-input
                    &.is-page-style
                        label
                            width: 100px
                            height: 100px
                            padding: 0
                            border-radius: 6px
                            overflow: hidden
                            border: 1px solid lighten(color-theme(dark-grey), 20)
                            @include breakpoint(mobile)
                                width: 60px
                                height: 60px
                            &[for="page-style-default"]
                                font-size: 14px
                                color: color-theme('grey300')
                                text-align: center
                                padding: 32px 0
                        svg
                            width: 100%
                            height: auto
                            max-width: 100px
                            fill: white
                            stroke: none
                            *
                                fill: white
                        input[type="radio"]:checked + label
                            border-color: rgba(var(--symbioze-rgb), .5)
                            svg
                                *
                                    fill: var(--symbioze)
    &--builder
        &-droppable-item
            margin-bottom: 20px
            .portlet
                margin: 0
        &-placeholder
            border-radius: 10px
            border: 3px dashed color-theme(light-grey)
            padding: 20px
            display: flex
            flex-direction: column
            align-items: center
            svg
                width: 48px
                height: auto
                margin-bottom: 10px
                stroke: color-theme('grey300')
            p
                color: color-theme('grey300')
        &-wrong-type
            background-color: color-theme(error)
            color: white
            border-radius: 6px
            padding: 4px 8px
            display: flex
            flex-direction: row
            align-items: center
            svg
                width: 18px
                height: auto
                stroke: white
                margin-right: 8px
            .portlet
                background-color: transparentize(color-theme('grey200'), .3)
                -webkit-backdrop-filter: blur(5px)
                backdrop-filter: blur(5px)
                &--title
                    background-color: color-theme('grey300')
                    border-bottom: 1px solid color-theme('grey300')
                    border-top-left-radius: 10px
                    border-top-right-radius: 10px