.tabs
    border-bottom: 1px solid color-theme('grey300')
    margin-bottom: 20px
    li,
    &--item
        color: white
        border: 1px solid color-theme('grey300')
        border-top-left-radius: 3px
        border-top-right-radius: 3px
        cursor: pointer
        display: inline-block
        vertical-align: middle
        padding: 8px 16px
        margin: 0 4px -1px
        &:first-child
            margin-left: 8px
        &:last-child
            margin-right: 8px
        &:hover,
        &.is-active
            color: var(--symbioze)
            border-bottom-color: color-theme(light-grey)