h1
    font-family: 'Montserrat', sans-serif
    font-weight: 500
    text-transform: uppercase
    letter-spacing: 3px
    color: white
    margin: 0
    padding-bottom: 10px

h2
    font-family: 'Montserrat', sans-serif
    font-weight: 500
    text-transform: uppercase
    letter-spacing: 3px
    color: white
    margin: 0
    padding-bottom: 10px

h3
    font-family: 'Montserrat', sans-serif
    font-weight: 500
    text-transform: uppercase
    letter-spacing: 3px
    color: white
    margin: 0
    padding-bottom: 10px