.header
    width: 100%
    background-color: var(--neutral-200)
    a 
        &:hover
            color: color-theme(primary)
    &--container
        display: flex
        flex-direction: row
        align-items: center
        @include breakpoint(mobile)
            justify-content: space-between
        .log-out
            button
                width: 150px
    &--brand
        padding: 15px 20px 16px 0
        position: relative
        @include breakpoint(mobile)
            padding: 8px 0
        svg
            width: 105px
            height: 26px
            display: block
            fill: white
            position: relative
            z-index: 1
    &--mobile-menu
        width: 24px
        height: 24px
        display: none
        flex-direction: column
        justify-content: center
        align-items: center
        overflow: hidden
        @include breakpoint(mobile)
            display: flex
        span
            width: 18px
            height: 2px
            background-color: color-theme('grey500')
            border-radius: 2px
            transition: all .3s ease
            opacity: 1
            margin-bottom: 4px
            display: block
            &:last-child
                margin-bottom: 0
        &.is-opened
            span
                &:nth-child(1)
                    transform: rotate(-45deg) translateX(-4px) translateY(4px)
                &:nth-child(2)
                    transform: translateX(-24px)
                    opacity: 0
                &:nth-child(3)
                    transform: rotate(45deg) translateX(-4px) translateY(-4px)
    &--navigation
        width: 80%
        background-color: color-theme(grey100)
        padding-left: 20px
        @include breakpoint(mobile)
            display: none
        ul
            li
                color: color-theme('grey400')
                transition: all .2s ease
                display: inline-block
                position: relative
                padding: 20px 8px
                margin: 0 10px
                border-radius: 3px
                &:first-child
                    margin-left: 0
                &:last-child
                    margin-right: 0
                &:hover
                    color: color-theme('grey500')
                    ul
                        display: block
                &.has-dropdown
                    cursor: pointer
                strong
                    font-weight: 500
                svg
                    width: 12px
                    height: 12px
                    stroke: color-theme('grey500')
                    margin-left: 4px
                a
                    color: color-theme('grey400')
                    transition: all .2s ease
                    &.is-active
                        color: var(--symbioze)
                    &:hover
                        color: color-theme('grey500')
                ul
                    min-width: 140px
                    position: absolute
                    z-index: 5
                    top: 57px
                    left: 0
                    display: none

                    background-color: color-theme(grey100)
                    border: 1px solid darken(color-theme(grey100), 3)
                    border-top: none
                    box-shadow: 0 4px 4px transparentize(black, .8)
                    border-bottom-left-radius: 4px
                    border-bottom-right-radius: 4px
                    overflow: hidden
                    li
                        display: block
                        margin: 0
                        padding: 0
                        border-bottom: 1px solid lighten(color-theme(grey100), 3)
                        &.is-logout-menu
                            padding: 4px 8px
                        &:last-child
                            border-bottom: none
                        a
                            font-size: 12px
                            font-weight: 500
                            white-space: nowrap
                            padding: 4px 8px
                            display: block
                            &:hover
                                background-color: color-theme(primary)
                                color: color-theme(grey100)
        &-mobile
            width: 100vw
            height: calc(100vh - 42px)
            background-color: var(--neutral-200)
            position: fixed
            z-index: 10
            top: 42px
            transition: all .2s ease
            transform: translateX(-100vw)
            &.is-opened
                transform: translateX(0)
            .header
                &--navigation
                    width: initial
                    display: block
                    &-dropdown
                        display: block
                        position: relative
                    ul
                        background: transparent
                        border: none
                        box-shadow: none
                        display: flex
                        flex-direction: column
                        position: relative
                        padding: 20px 0
                        li
                            padding: 8px 0
                            margin: 0
                            &.has-dropdown
                                padding: 20px 0
                            strong
                                color: lighten(color-theme('grey300'), 5)
                                text-transform: uppercase
                                display: block
                            svg
                                display: none
                        ul
                            padding: 0
                            margin: 0
                            top: 0
                            li
                                margin: 0
                                border-bottom: none
                                a
                                    font-size: 14px
                                    padding: 0
                                    &:hover
                                        color: color-theme('grey400')
                                        background-color: initial

    &--profile
        flex-grow: 1
        flex-shrink: 0
        align-items: flex-end
        display: flex
        flex-direction: column
        position: relative
        &-user
            width: auto
            display: inline-flex
            align-items: center
            transition: all .2s ease
            color: color-theme('grey500')
            cursor: pointer
            &:hover
                color: color-theme('grey400')
                .header--profile-user-avatar
                    opacity: .7
            span
                white-space: nowrap
                @include breakpoint(mobile)
                    display: none
            &-avatar
                width: 28px
                height: 28px
                font-size: 14px
                font-weight: 600
                color: var(--neutral-200)
                border-radius: 28px
                background-color: var(--symbioze)
                transition: all .2s ease
                margin-left: 10px
                display: flex
                align-items: center
                justify-content: center
                @include breakpoint(mobile)
                    width: 26px
                    height: 26px
                    font-size: 12px
                    margin-left: 0