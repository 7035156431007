// SASS - MIXINS
%clearfix {
	*zoom: 1;
	&:before,
	&:after {
		content: '';
		display: table;
	}
	&:after {
		clear: both;
	}
}

@mixin breakpoint($point) {
	@if $point == desktop {
		@media (min-width: $desktop-max-width) { @content ; }
	}
	@else if $point == tablet {
		@media (min-width: $tablet-max-width) { @content ; }
	}
	@else if $point == mobile {
		@media (max-width: $mobile-max-width)  { @content ; }
	}
}

@mixin breakpointWeb($point) {
	@if $point == desktop {
		@media (min-width: $mobile-max-width)  { @content ; }
		.is-desktop &  { @content ; }
	}
	@if $point == mobile {
		@media (max-width: $mobile-max-width)  { @content ; }
		.is-mobile &  { @content ; }
	}
}

@mixin landscape() {
	@media (orientation: landscape) { @content ; }
}

@mixin pixel-ratio($pixelRatio) {
	@media (-webkit-min-device-pixel-ratio: $pixelRatio) { @content ; }
	@media (min-resolution: #{$pixelRatio}dppx) { @content ; }
}

@mixin vp-reduit {
	@media only screen and (max-width: 1200px) {
		@content;
	}
}

@mixin vp-tablet {
	@media only screen and (max-width: 768px) {
		@content;
	}
}

@mixin vp-mobile {
	@media only screen and (max-width: 640px) {
		@content;
	}
}

// ---------------------
@mixin abs-pos($top: auto, $left: auto, $bottom: auto, $right: auto) {
	position: absolute;
	top: $top;
	left: $left;
	bottom: $bottom;
	right: $right;
}

@mixin animation($str) {
	animation: $str;
}

@mixin background($backgrounds...) {
	$webkit-backgrounds: ();
	$spec-backgrounds: ();

	@each $background in $backgrounds {
	$webkit-background: ();
	$spec-background: ();
	$background-type: type-of($background);

	@if $background-type == string or $background-type == list {
		$background-str: if($background-type == list, nth($background, 1), $background);

		$url-str:       str-slice($background-str, 0, 3);
		$gradient-type: str-slice($background-str, 0, 6);

		@if $url-str == "url" {
			$webkit-background: $background;
			$spec-background:   $background;
		}

		@else if $gradient-type == "linear" {
			$gradients: _linear-gradient-parser("#{$background}");
			$webkit-background: map-get($gradients, webkit-image);
			$spec-background:   map-get($gradients, spec-image);
		}

		@else if $gradient-type == "radial" {
			$gradients: _radial-gradient-parser("#{$background}");
			$webkit-background: map-get($gradients, webkit-image);
			$spec-background:   map-get($gradients, spec-image);
		}

		@else {
			$webkit-background: $background;
			$spec-background:   $background;
		}
	}

	@else {
		$webkit-background: $background;
		$spec-background:   $background;
	}

	$webkit-backgrounds: append($webkit-backgrounds, $webkit-background, comma);
	$spec-backgrounds:   append($spec-backgrounds,   $spec-background,   comma);
	}

	background: $webkit-backgrounds;
	background: $spec-backgrounds;
}

@mixin background-image($images...) {
	$webkit-images: ();
	$spec-images: ();

	@each $image in $images {
		$webkit-image: ();
		$spec-image: ();

		@if (type-of($image) == string) {
			$url-str:       str-slice($image, 0, 3);
			$gradient-type: str-slice($image, 0, 6);

		@if $url-str == "url" {
			$webkit-image: $image;
			$spec-image:   $image;
		}

		@else if $gradient-type == "linear" {
			$gradients: _linear-gradient-parser($image);
			$webkit-image:  map-get($gradients, webkit-image);
			$spec-image:    map-get($gradients, spec-image);
		}

		@else if $gradient-type == "radial" {
			$gradients: _radial-gradient-parser($image);
			$webkit-image: map-get($gradients, webkit-image);
			$spec-image:   map-get($gradients, spec-image);
		}
	}

	$webkit-images: append($webkit-images, $webkit-image, comma);
	$spec-images:   append($spec-images,   $spec-image,   comma);
	}

	background-image: $webkit-images;
	background-image: $spec-images;
}

@mixin border-box {
	box-sizing: border-box;
}

@mixin box-emboss($opacity, $opacity2){
    @include box-shadow(white($opacity) 0 1px 0, inset black($opacity2) 0 1px 0);
}

@mixin box-shadow($params) {
	box-shadow: $params;
}

@mixin calc($property, $value) {
	#{$property}: -webkit-calc(#{$value});
	#{$property}: calc(#{$value});
}

@mixin center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

@mixin container($size) {
	width: $size;
	max-width: $grid-max-width;
}

@mixin hover3dButton() {
	position: relative;
	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		right: 50%;
		left: 50%;
		top: 0;
		border-radius: inherit;
		background-color: rgba(255, 255, 255, 0.15);
		transition: all .3s ease;
		opacity: 0;
	}
	&:hover:after {
		left: 0;
		right: 0;
		opacity: 1;
	}
}

@mixin hamburger-menu($width, $height, $padding, $bg, $a-bg, $color, $a-color, $rounded: 3px) {
    width: $mobile-header-height;
    height: $mobile-header-height;
    padding: $padding;
    vertical-align: top;
    background-color: $bg;
    &.active {
        background-color: $a-bg;
    }
    .hamburger {
        width: $width;
        height: $height;
        display: block;
        position: relative;
        cursor: pointer;
        span,
        span:before,
        span:after {
            display: block;
            width: $width;
            height: 2px;
            background-color: $color;
            -webkit-border-radius: $rounded;
            -moz-border-radius: $rounded;
            border-radius: $rounded;
            position: absolute;
            left: 0;
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            transition: all 0.3s;
        }
        span:before,
        span:after {
            content: '';
        }
        span {
            top: ($height / 5) * 2;
        }
        span:before {
            -webkit-transform-origin: (33%, 100%);
            -moz-transform-origin: (33%, 100%);
            transform-origin: (33%, 100%);
            top: -(($height / 5) * 2);
        }
        span:after {
            -webkit-transform-origin: (33%, 0);
            -moz-transform-origin: (33%, 0);
            transform-origin: (33%, 0);
            top: ($height / 5) * 2;
        }
    }
    &.active .hamburger span,
    &.active .hamburger span:before,
    &.active .hamburger span:after {
        background-color: transparent;
    }
    &.active .hamburger span:before {
        -webkit-transform: translateY(($height / 5) * 2) rotate(45deg);
        -moz-transform: translateY(($height / 5) * 2) rotate(45deg);
        transform: translateY(($height / 5) * 2) rotate(45deg);
        background-color: $a-color;
    }
    &.active .hamburger span:after {
        -webkit-transform: translateY(-($height / 5) * 2) rotate(-45deg);
            -moz-transform: translateY(-($height / 5) * 2) rotate(-45deg);
                transform: translateY(-($height / 5) * 2) rotate(-45deg);
                    background-color: $a-color;
    }
}

@mixin keyframes($animation-name) {
	@-webkit-keyframes #{$animation-name} {
		@content;
	}
	@-moz-keyframes #{$animation-name} {
		@content;
	}
	@-ms-keyframes #{$animation-name} {
		@content;
	}
	@-o-keyframes #{$animation-name} {
		@content;
	}
	@keyframes #{$animation-name} {
		@content;
	}
}

@mixin letterpress($opacity){
    text-shadow: white($opacity) 0 1px 0;
}

@mixin linear-gradient($pos, $g1, $g2: null,
                       $g3: null, $g4: null,
                       $g5: null, $g6: null,
                       $g7: null, $g8: null,
                       $g9: null, $g10: null,
                       $fallback: null) {
	$pos-type: type-of(nth($pos, 1));
	$pos-spec: null;
	$pos-degree: null;

	@if ($pos-type == color) or (nth($pos, 1) == "transparent")  {
		$g10: $g9; $g9: $g8; $g8: $g7; $g7: $g6; $g6: $g5;
		$g5: $g4; $g4: $g3; $g3: $g2; $g2: $g1; $g1: $pos;
		$pos: null;
	}

	@if $pos {
		$positions: _linear-positions-parser($pos);
		$pos-degree: nth($positions, 1);
		$pos-spec:   nth($positions, 2);
	}

	$full: $g1, $g2, $g3, $g4, $g5, $g6, $g7, $g8, $g9, $g10;

	$fallback-color: nth($g1, 1);

	@if (type-of($fallback) == color) or ($fallback == "transparent") {
		$fallback-color: $fallback;
	}

	background-color: $fallback-color;
	background-image: -webkit-linear-gradient($pos-degree $full);
	background-image: unquote("linear-gradient(#{$pos-spec}#{$full})");
}

@mixin opacity($opacity) {
	opacity: $opacity;
	filter: $opacity * 100;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$opacity * 100})";
}

@mixin placeholder {
	$placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";
	@each $placeholder in $placeholders {
		&:#{$placeholder}-placeholder {
			@content;
		}
	}
}

@mixin rounded($radius) {
	border-radius: $radius;
}

@mixin transform($params) {
	transform: $params;
}

@mixin transform-origin($params) {
	transform-origin: $params;
}

@mixin transform-style($style: preserve-3d) {
	transform-style: $style;
}

@mixin triangle($size, $color, $direction) {
	$width: nth($size, 1);
	$height: nth($size, length($size));
	$foreground-color: nth($color, 1);
	$background-color: if(length($color) == 2, nth($color, 2), transparent);
	height: 0;
	width: 0;

	@if ($direction == up) or ($direction == down) or ($direction == right) or ($direction == left) {
		$width: $width / 2;
		$height: if(length($size) > 1, $height, $height/2);

		@if $direction == up {
			border-bottom: $height solid $foreground-color;
			border-left: $width solid $background-color;
			border-right: $width solid $background-color;
		} @else if $direction == right {
			border-bottom: $width solid $background-color;
			border-left: $height solid $foreground-color;
			border-top: $width solid $background-color;
		} @else if $direction == down {
			border-left: $width solid $background-color;
			border-right: $width solid $background-color;
			border-top: $height solid $foreground-color;
		} @else if $direction == left {
			border-bottom: $width solid $background-color;
			border-right: $height solid $foreground-color;
			border-top: $width solid $background-color;
		}
	} @else if ($direction == up-right) or ($direction == up-left) {
		border-top: $height solid $foreground-color;

		@if $direction == up-right {
			border-left:  $width solid $background-color;
		} @else if $direction == up-left {
			border-right: $width solid $background-color;
		}
	} @else if ($direction == down-right) or ($direction == down-left) {
		border-bottom: $height solid $foreground-color;

		@if $direction == down-right {
			border-left:  $width solid $background-color;
		} @else if $direction == down-left {
			border-right: $width solid $background-color;
		}
	} @else if ($direction == inset-up) {
		border-color: $background-color $background-color $foreground-color;
		border-style: solid;
		border-width: $height $width;
	} @else if ($direction == inset-down) {
		border-color: $foreground-color $background-color $background-color;
		border-style: solid;
		border-width: $height $width;
	} @else if ($direction == inset-right) {
		border-color: $background-color $background-color $background-color $foreground-color;
		border-style: solid;
		border-width: $width $height;
	} @else if ($direction == inset-left) {
		border-color: $background-color $foreground-color $background-color $background-color;
		border-style: solid;
		border-width: $width $height;
	}
}

@mixin vertical-align {
	position: relative;
	top: 50%;
	@include transform(translateY(-50%));
}