.viewport
    --primary-color: blue
    --primary-color-rgb: 0,255,0
    --secondary-color: blue
    --secondary-color-rgb: 0,255,0
    --header-color: transparent
    --header-color-rgb: 0,0,0
    --header-text-color: #000
    --text-color: #000
    --text-color-rgb: 0,0,0
    --text-headers-color: #000

.page-builder
    &--preview
        &-comp
            overflow: hidden
            position: absolute
            pointer-events: none
            bottom: 0
            right: 0
            left: 0
            top: 0
            &-name
                font-size: 12px
                font-weight: 500
                color: var(--neutral-200)
                background-color: var(--symbioze)
                border-bottom-right-radius: 6px
                padding: 4px 16px
                transition: all .2s ease
                position: absolute
                z-index: 2
                top: -50px
                left: 0
            &-border
                box-shadow: inset 0 0 0 0 var(--symbioze)
                position: absolute
                bottom: 0
                right: 0
                left: 0
                top: 0
            button
                svg
                    stroke: var(--neutral-200)
    &--web
        border-radius: 10px
        background-color: color-theme('grey500')
        border: 1px solid var(--neutral-200)
        transition: max-width .2s ease
        margin: 20px 0
        overflow: hidden
        &.is-desktop
            max-width: calc(1360px + 60px)
            margin: 20px auto 0
        &.is-mobile
            max-width: calc(360px + 60px)
            margin: 20px auto 0
        *
            transition: all .2s ease
        button
            &.is-page-content-selector-button
                width: 32px
                height: 32px
                border-radius: 32px
                transition: all .2s ease
                position: absolute
                pointer-events: all
                z-index: 2
                top: -100px
                right: 14px
                padding: 6px
                svg
                    width: 18px
                    height: 18px
        &-text,
        &-gallery,
        &-slideshow,
        &-contact-request,
        &-accordion,
        &-sections-hero-section,
        &-sections-call-to-action
            position: relative
            &:hover,
            &.is-selected
                .page-builder--preview-comp-name
                    top: 0
                .page-builder--preview-comp-border
                    box-shadow: inset 0 0 0 2px var(--symbioze)
                .is-page-content-selector-button
                    top: 14px

        // BLOCK --- TEXT
        &-text
            background-repeat: no-repeat
            background-size: cover
            background-position: center center
            &.has-background-image
                &:after
                    content: ''
                    background-color: var(--block-overlay-color, black)
                    opacity: var(--block-overlay-opacity, .3)
                    position: absolute
                    z-index: 1
                    bottom: 0
                    right: 0
                    left: 0
                    top: 0
                .container
                    position: relative
                    z-index: 2
            &.is-dark-theme
                .wysiwyg
                    h1,
                    h2,
                    h3,
                    h4,
                    h5,
                    h6
                        color: white
                    p
                        color: transparentize(white, .3)

        // BLOCK --- ACCORDION
        &-accordion
            background-repeat: no-repeat
            background-size: cover
            background-position: center center
            &.has-background-image
                &:after
                    content: ''
                    background-color: var(--block-overlay-color, black)
                    opacity: var(--block-overlay-opacity, .3)
                    position: absolute
                    z-index: 1
                    bottom: 0
                    right: 0
                    left: 0
                    top: 0
                .container
                    position: relative
                    z-index: 2
            &-list
                border-radius: 6px
                &.is-bordered
                    border: 1px solid var(--text-color)
                    .is-dark-theme &
                        border: 1px solid transparentize(white, .7)
                &-item
                    &-question
                        font-size: 16px
                        font-weight: 600
                        border-bottom: 1px solid var(--text-color)
                        color: var(--text-color)
                        cursor: pointer
                        display: flex
                        flex-direction: row
                        justify-content: space-between
                        align-items: center
                        padding: 6px 0
                        user-select: none
                        .is-dark-theme &
                            border-bottom: 1px solid transparentize(white, .7)
                            color: transparentize(white, .1)
                        .is-bordered &
                            padding: 6px 12px
                        svg
                            width: 24px
                            height: 24px
                            transition: all .2s ease
                            fill: var(--text-color)
                            flex-shrink: 0
                            margin-left: 1rem
                            .is-dark-theme &
                                fill: transparentize(white, .3)
                            .is-opened &
                                transform: rotate(180deg)
                    &-answer
                        padding: 10px 0 20px 0
                        display: none
                        .is-bordered &
                            padding: 10px 12px 20px
                            border-bottom: 1px solid var(--text-color)
                            .is-dark-theme &
                                border-bottom: 1px solid transparentize(white, .7)
                        .is-opened &
                            display: block
                        p
                            color: var(--text-color)
                            .is-dark-theme &
                                color: transparentize(white, .3)
                    &:last-child
                        &:not(.is-opened)
                            .is-bordered &
                                .page-builder--web-accordion-list-item-question
                                    border-bottom: none
                        .page-builder--web-accordion-list-item-answer
                            border-bottom: none

        // BLOCK --- GALLERY
        &-gallery
            background-repeat: no-repeat
            background-size: cover
            background-position: center
            &.has-background-image
                &:after
                    content: ''
                    background-color: var(--block-overlay-color, black)
                    opacity: var(--block-overlay-opacity, .3)
                    position: absolute
                    z-index: 1
                    bottom: 0
                    right: 0
                    left: 0
                    top: 0
                .page-builder--web-gallery-list
                    position: relative
                    z-index: 2
            &-list
                display: grid
                grid-template-columns: repeat(4, 1fr)
                align-items: start
                column-gap: 20px
            &-item
                margin-bottom: 20px
                display: inline-block
                transition: all .1s ease
                &-image
                    overflow: hidden
                    position: relative
                    img
                        width: 100%
                        display: block
                &-info
                    padding: 10px 0 0 0
                    position: relative
                    .page-builder--web-gallery-item-image &
                        padding: 10px
                        background-color: transparentize(black, .7)
                        backdrop-filter: blur(10px)
                        position: absolute
                        z-index: 2
                        bottom: 0
                        right: 0
                        left: 0
                    &:has(.page-builder--web-gallery-item-info-description)
                        .page-builder--web-gallery-item-image
                            text-align: center
                    &-title
                        font-size: 14px
                        font-weight: 600
                        margin-bottom: 4px
                        color: var(--text-color)
                        @include breakpointWeb('mobile')
                            font-size: 12px
                        .is-dark-theme &
                            color: white
                    &-description
                        opacity: .7
                        color: var(--text-color)
                        @include breakpointWeb('mobile')
                            font-size: 10px
                        .is-dark-theme &
                            color: transparentize(white, .3)

        // BLOCK --- SLIDESHOW
        &-slideshow
            background-repeat: no-repeat
            background-size: cover
            background-position: center
            &.has-background-image
                &:after
                    content: ''
                    background-color: var(--block-overlay-color, black)
                    opacity: var(--block-overlay-opacity, .3)
                    position: absolute
                    z-index: 1
                    bottom: 0
                    right: 0
                    left: 0
                    top: 0
                .container
                    position: relative
                    z-index: 2
            &-slide
                position: relative
                &-overlay
                    background: linear-gradient(0deg, rgba(0,0,0,.6) 0%, rgba(0,0,0,0) 100%)
                    padding: 20px
                    position: absolute
                    z-index: 1
                    bottom: 0
                    right: 0
                    left: 0
                    span
                        font-size: 24px
                        font-weight: 600
                        color: color-theme('grey500')
                        @include breakpointWeb('mobile')
                            font-size: 18px
                    p
                        padding-top: 10px
                        font-size: 14px
                        font-weight: 500
                        line-height: 20px
                        color: color-theme('grey500')
                        @include breakpointWeb('mobile')
                            font-size: 12px
                            line-height: 16px
            .swiper
                &.has-dots
                    padding-bottom: 30px
                &-slide
                    width: auto
                    user-select: none
                    img
                        width: 100%
                        height: auto
                        display: block
                &-pagination
                    &-bullet
                        background: color-theme('grey400')
                        &-active
                            background: var(--symbioze)
                &-button
                    &-next,
                    &-prev
                        color: var(--symbioze)
                        &:after
                            font-size: 24px
            &-thumbnails
                .swiper
                    &-slide
                        width: 64px
                        height: 48px
                        cursor: pointer
                        margin-top: 10px
                        img
                            width: 64px
                            height: 48px
                            border-radius: 3px
                            opacity: .5
                            display: block
                        &.is-active
                            img
                                opacity: 1

        // BLOCK --- CONTACT REQUESTS
        &-contact-requests
            background-repeat: no-repeat
            background-size: cover
            background-position: center
            &.has-background-image
                &:after
                    content: ''
                    background-color: var(--block-overlay-color, black)
                    opacity: var(--block-overlay-opacity, .3)
                    position: absolute
                    z-index: 1
                    bottom: 0
                    right: 0
                    left: 0
                    top: 0
                .container
                    position: relative
                    z-index: 2
            .container
                max-width: 900px
                margin: 0 auto
                .form
                    &--group
                        border-bottom: none
                        label
                            color: var(--text-color)
                        .button
                            background-color: var(--primary-color)
                            color: var(--text-color)
                    &--validations
                        &-error
                            &:empty
                                margin: 0
            &.is-dark-theme
                .form
                    &--group
                        border-bottom-color: transparentize(white, .8)
                        label
                            color: transparentize(white, .3)
                        input[type="text"],
                        input[type="email"],
                        input[type="password"],
                        input[type="tel"],
                        input[type="date"]
                            background-color: transparentize(white, .8)
                            border: none
                            color: white
                            &:focus
                                border-color: var(--primary-color)
                            &::placeholder
                                opacity: .3
                                color: white
                        select
                            background-color: transparentize(white, .8)
                            border: none
                            color: white
                            &:focus
                                border-color: var(--primary-color)
                        textarea
                            background-color: transparentize(white, .8)
                            border: none
                            color: white
                            &:focus
                                border-color: var(--primary-color)
                            &::placeholder
                                opacity: .3
                                color: white
        // BLOCK - Sections HeroSection
        &-sections-hero-section
            background-repeat: no-repeat
            background-size: cover
            background-position: center center
            display: flex
            flex-direction: column
            justify-content: center
            .grid-wrapper
                flex-grow: 0
            &.has-background-image
                &:after
                    content: ''
                    background-color: var(--block-overlay-color, black)
                    opacity: var(--block-overlay-opacity, .3)
                    position: absolute
                    z-index: 1
                    bottom: 0
                    right: 0
                    left: 0
                    top: 0
                .container
                    position: relative
                    z-index: 2
            &-height
                min-height: 90vh
                display: flex
                flex-direction: column
                justify-content: center
            &-container
                display: flex
                flex-direction: column
                justify-content: center
                &.is-left-aligned
                    align-items: flex-start
                &.is-center-aligned
                    align-items: center
                &.is-right-aligned
                    align-items: flex-end
            &-logo
                padding-bottom: 30px
            &-title
                font-family: "Gilroy", sans-serif
                font-size: 48px
                font-weight: 600
                text-transform: none
                color: var(--text-color)
                letter-spacing: normal
                padding-bottom: 10px
                @include breakpointWeb('mobile')
                    font-size: 36px
                .is-left-aligned &
                    text-align: left
                .is-center-aligned &
                    text-align: center
                .is-right-aligned &
                    text-align: right
                .is-dark-theme &
                    color: white
            &-subtitle
                font-family: "Gilroy", sans-serif
                font-size: 18px
                font-weight: 500
                text-transform: none
                color: var(--text-color)
                letter-spacing: normal
                @include breakpointWeb('mobile')
                    font-size: 16px
                .is-left-aligned &
                    text-align: left
                .is-center-aligned &
                    text-align: center
                .is-right-aligned &
                    text-align: right
                .is-dark-theme &
                    color: white
            &-btn
                padding-top: 30px
                .button
                    font-size: 16px
                    text-transform: uppercase
                    min-width: 220px
                    padding: 16px 32px
                    .is-dark-theme &
                        background-color: white
                        colorl: var(--text-color)
                    // -----------------------------------------------
                    // @TODO --- Better button style with design system
                    // -----------------------------------------------
                    &.is-dark
                        background-color: black
                        color: white
                    &.is-white
                        background-color: white
                        color: black
        // BLOCK - Sections Call To Action
        &-sections-call-to-action
            &-title
                font-size: 24px
                font-weight: 600
                color: var(--text-color)
                text-align: center
                @include breakpointWeb('mobile')
                    font-size: 20px
                .is-dark-theme &
                    color: white
                strong
                    color: var(--primary-color)
                    font-weight: 600
            &-btn
                padding-top: 20px
                text-align: center
                button
                    background-color: var(--primary-color)
                    color: var(--text-color)
                    padding-left: 36px
                    padding-right: 36px
.wysiwyg
    &--block-600
        width: 100%
        max-width: 600px
        margin: 0 auto
    h1,
    h2,
    h3,
    h4,
    h4,
    h5,
    h6
        font-family: 'Gilroy', sans-serif
        font-weight: 600
        font-style: normal
        text-transform: none
        letter-spacing: normal
        color: var(--text-headers-color)
        word-wrap: anywhere
    h1
        font-size: 56px
        padding-bottom: 20px
        @include breakpointWeb('mobile')
            font-size: 32px
    h2
        font-size: 48px
        padding-bottom: 16px
        @include breakpointWeb('mobile')
            font-size: 28px
    h3
        font-size: 32px
        padding-bottom: 12px
        @include breakpointWeb('mobile')
            font-size: 24px
    h4
        font-size: 22px
        padding-bottom: 8px
        @include breakpointWeb('mobile')
            font-size: 18px
    h5
        font-size: 18px
        padding-bottom: 4px
        @include breakpointWeb('mobile')
            font-size: 18px

    a
        color: var(--primary-color)

    p
        font-size: 14px
        line-height: 20px
        color: var(--text-color)
        margin-bottom: 10px

    table
        tr
            &:nth-child(odd)
                td
                    background-color: transparent

    blockquote
        background-color: #F1F1F1
        padding: 5px 16px 4px
        border-radius: 4px
        border-left: 2px solid var(--secondary-color)
        margin: 4px 0 0 0
        p
            font-size: 12px
            font-style: italic
            color: rgba(var(--text-color-rgb), .5)

.is-desktop-hidden
    @include breakpointWeb('desktop')
        display: none

.is-mobile-hidden
    @include breakpointWeb('mobile')
        display: none