.search
    &--input
        display: flex
        flex-direction: row
        margin-bottom: 10px
        @include breakpoint(mobile)
            flex-wrap: wrap
        select
            width: 160px
            background-color: color-theme('grey200')
            color: white
            flex-grow: 0
            flex-shrink: 0
            border-bottom-right-radius: 0
            border-top-right-radius: 0
            @include breakpoint(mobile)
                width: 100%
        input[type="text"]
            border-radius: 0
            background-color: var(--neutral-200)
            border-left-width: 0
            border-right-width: 0
            @include breakpoint(mobile)
                flex-grow: 0
                flex-basis: 50%
                border-right-width: 1px
                border-bottom-right-radius: 4px
                border-top-right-radius: 4px
        button
            min-width: 54px
            font-size: 16px
            flex-grow: 0
            flex-shrink: 0
            border-bottom-left-radius: 0
            border-top-left-radius: 0
            @include breakpoint(mobile)
                flex-basis: 100%
                margin-top: 10px
                border-bottom-left-radius: 3px
                border-top-left-radius: 3px
        &-items
            display: flex
            flex-direction: row
            margin-bottom: 20px
        &-item
            background-color: var(--neutral-200)
            border: 1px dashed color-theme('grey300')
            border-radius: 4px
            display: flex
            flex-direction: row
            align-items: center
            margin-right: 10px
            padding: 8px 16px
            &:last-child
                margin-right: 0
            &-info
                flex-grow: 1
                &-type
                    font-family: 'Montserrat'
                    font-size: 9px
                    letter-spacing: 1px
                    color: color-theme('grey400')
                    text-transform: uppercase
                &-value
                    font-size: 14px
                    color: color-theme('grey500')
                    padding-top: 2px
            &-close
                height: 14px
                flex-shrink: 0
                padding-left: 18px
                &-icon
                    cursor: pointer
                    &:hover
                        svg
                            stroke: var(--symbioze)
                    svg
                        width: 14px
                        height: 14px
                        stroke: color-theme('grey400')