.Toastify
    &__toast
        font-family: "Gilroy", sans-serif
        min-height: inherit
        background: color-theme('grey200')
        box-shadow: none
        border: none
        border-radius: 4px
        &-body
            padding: 2px 2px 5px
        &--error
            background: color-theme('error')
    &__progress-bar
        height: 3px
        border-radius: 3px
        background-color: white
        margin: 0px 10px 6px 10px
        &--error
            background-color: white
        &--success
            background-color: color-theme('success')
    &__close
        &-button
            svg
                fill: white
