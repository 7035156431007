.portlet
    width: 100%
    background-color: var(--neutral-100)
    border: 1px solid var(--neutral-200)
    box-shadow: 0 2px 8px rgba(0, 0, 0, .1)
    border-radius: 10px
    display: block
    margin: 0 0 20px
    .is-dark-mode &
        border: none
        box-shadow: none
        background-color: rgba(var(--neutral-200-rgb), .5)
    .is-black-mode &
        border: none
        box-shadow: none
    &--title
        padding: 0 25px
        border-bottom: 1px solid var(--neutral-200)
        display: flex
        flex-direction: row
        justify-content: space-between
        .is-closed &
            border-bottom: none
        .is-compact &
            padding: 0 18px
        &-label
            font-size: 16px
            font-weight: 600
            color: var(--default-text)
            line-height: 60px
            flex-grow: 1
            display: flex
            flex-direction: row
            align-items: center
            .is-compact &
                font-size: 12px
                line-height: 42px
            svg
                width: 24px
                height: auto
                stroke: white
                margin-right: 10px
                .is-compact &
                    width: 16px
        &-toolbar
            line-height: 60px
            display: flex
            align-items: center
            align-content: flex-end
            .is-compact &
                line-height: 42px
            .button
                min-width: initial
                color: darken(white, 50%)
                padding: 6px 7px 5px
                margin: 0 2px
                display: flex
                &:first-child
                    margin-left: 0
                &:last-child
                    margin-right: 0
                .material-icons
                    font-size: 14px
                    .is-compact &
                        font-size: 9px
    &--body
        font-weight: 300
        padding: 25px
        transition: all .3s ease
        @include breakpoint(mobile)
            padding: 12px
        .is-closed &
            display: none
        .is-compact &
            padding: 18px