.file-manager
    &--list
        &-file
            width: 145px
            height: 180px
            background-color: var(--neutral-200)
            background-size: cover
            background-position: center center
            transition: all .2s ease
            transform: scale(1.0)
            border-radius: 10px
            position: relative
            overflow: hidden
            padding: 20px 12px 
            display: flex
            flex-direction: column
            justify-content: space-between
            margin-bottom: 20px
            margin-right: 20px
            .is-list-view &
                width: 100%
                height: auto
                flex-direction: row
                justify-content: flex-start
                align-items: center
                background-image: none !important
                padding: 10px
                margin-right: 0
            &:hover
                background-color: var(--symbioze)
                transform: scale(1.05)
                .file-manager--list-file-icon
                    background-color: transparentize(white, .5)
                    svg
                        stroke: var(--neutral-200)
            &:active
                transform: scale(1.0)
            &.is-image
                &:after
                    content: ''
                    display: block
                    background-color: transparentize(black, .8)
                    position: absolute
                    z-index: 1
                    bottom: 0
                    right: 0
                    left: 0
                    top: 0
                    .is-list-view &
                        display: none
                &:hover
                    &:after
                        background-color: rgba(var(--symbioze-rgb), .2)
            &-icon
                width: 42px
                height: 42px
                border-radius: 100px
                background-color: color-theme('grey200')
                transition: all .2s ease
                display: flex
                flex-direction: column
                justify-content: center
                align-items: center
                flex-grow: 0
                flex-shrink: 1
                position: relative
                z-index: 2
                .is-list-view &
                    width: 36px
                    height: 36px
                    margin-right: 10px
                svg
                    width: 24px
                    height: 24px
                    transition: all .2s ease
                    stroke: color-theme('grey400')
                    display: block
                    .is-list-view &
                        width: 18px
                        height: 18px
            &-details
                display: flex
                flex-direction: column
                justify-content: flex-end
                flex-grow: 1
                .is-list-view &
                    flex-direction: row
                    justify-content: space-between
                    align-items: center
                &-image
                    width: 36px
                    height: 36px
                    border-radius: 6px
                    flex-grow: 0
                    flex-shrink: 0
                    overflow: hidden
                    display: none
                    margin-right: 10px
                    .is-list-view &
                        display: block
                    img
                        width: 36px
                        height: 36px
                        object-fit: cover
                        object-position: center
                &-name
                    font-size: 14px
                    font-weight: 600
                    color: color-theme('grey500')
                    transition: all .2s ease
                    padding-bottom: 10px
                    position: relative
                    z-index: 2
                    .is-list-view &
                        padding-bottom: 2px
                        flex-grow: 1
                &-items
                    font-size: 12px
                    color: color-theme('grey400')
                    transition: all .2s ease
                    position: relative
                    z-index: 2