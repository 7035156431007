.viewport
    .button
        font-size: 12px
        font-weight: 600
        letter-spacing: .5px
        min-width: 150px
        background-color: var(--primary-color)
        color: var(--text-color)
        text-align: center
        transition: all .2s ease
        cursor: pointer
        border-radius: 3px
        display: inline-block
        padding: 8px 20px
        position: relative
        overflow: hidden
        &:after
            content: ''
            transition: all .2s ease
            background-color: transparentize(white, .9)
            position: absolute
            bottom: 0
            right: 50%
            left: 50%
            top: 0
        &:hover
            &:after
                right: 0
                left: 0
        &:active
            transform: scale(0.95)
        svg
            width: 15px
            fill: white
            vertical-align: -3px
            margin-right: 4px
        &.is-small
            min-width: auto
            font-size: 12px
            padding: 4px 10px
            .material-icons
                font-size: 12px
                vertical-align: -2px